<script>
  import { createEventDispatcher } from "svelte";
  import { currentLanguage } from "../../store";
  import translations from "../../translations";

  const dispatch = createEventDispatcher();

  export let options = []; // The gender options (dataObject)
  export let label = ""; // The label for the field (label)
  export let showWritten = true; // Whether to show the "writtenName"

  let selectedGender = "";
  let hasSelected = false;
  let currentLang = "en";

  // Subscribe to changes in currentLanguage
  currentLanguage.subscribe((value) => {
    currentLang = value;
  });

  // When the user selects a gender option, store it but don't send yet
  function handleSelection(event) {
    selectedGender = event.target.value;
    hasSelected = true; // Enable the button when a selection is made
  }

  // Send the selected value when the button is clicked
  function sendSelection() {
    if (hasSelected) {
      dispatch("message", selectedGender); // Emit the selected value to the parent component
      hasSelected = false; // Disable the button after sending
    }
  }
</script>

<div class="chatwith-gender-selector">
  <div class="chatwith-radio-buttons-container">
    <label>
      <input class="chatwith-radio-button" type="radio" name="gender" value="M" on:change={handleSelection} />
      {translations.male[currentLang]}
    </label>
    <label>
      <input class="chatwith-radio-button" type="radio" name="gender" value="F" on:change={handleSelection} />
      {translations.female[currentLang]}
    </label>
    <label>
      <input class="chatwith-radio-button" type="radio" name="gender" value="Other" on:change={handleSelection} />
      {translations.other_gender[currentLang]}
    </label>
  </div>

  <button on:click={() => sendSelection()} class="chatwith-radio-buttons-sendbutton">
    <svg width="24px" height="24px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg"
      ><defs
        ><style>
          .cls-1 {
            fill: none;
          }
        </style></defs
      >
      <path
        fill="#fff"
        d="M27.45,15.11l-22-11a1,1,0,0,0-1.08.12,1,1,0,0,0-.33,1L7,16,4,26.74A1,1,0,0,0,5,28a1,1,0,0,0,.45-.11l22-11a1,1,0,0,0,0-1.78Zm-20.9,10L8.76,17H18V15H8.76L6.55,6.89,24.76,16Z"
      />
    </svg>
  </button>
</div>
