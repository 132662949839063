<script>
  import { createEventDispatcher } from "svelte";
  import { toArray, getWrittenName } from "../../utils/utilities";

  export let options;
  export let currency;
  $: value = "";

  const dispatch = createEventDispatcher();

  function action() {
    dispatch("message", value);
    value = "";
  }

  const reduceStr = (str) => {
    if (str.length > 20) return str.substring(0, 20) + "...";
    return str;
  };

  function selectOption(option) {
    value = option;
  }
</script>

<div class="scale-in-center">
  <div class="chatwith-button-options-container">
    <div class="chatwith-button-options">
      {#each toArray(options.data) as data}
        <button type="button" class="chatwith-option-button {value === data ? 'selected' : ''}" on:click={() => selectOption(data)}>
          {getWrittenName(data, options?.dataObject, options?.showPrices, currency)}
        </button>
      {/each}
    </div>

    <button on:click={() => action()} class="chatwith-chat-sendbutton">
      <svg style="width: 18px;" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        />
      </svg>
    </button>
  </div>
</div>
