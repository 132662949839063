<script>
  import { slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  export let data;
  $: fullScreenMobile = data.expandFullScreenMobile;

  const agentList = data.agents.filter((obj, index, array) => array.slice(0, index).filter((item) => item.name === obj.name).length === 0);
</script>

<div class="question-form-container" class:fullScreenMobile>
  <div class="question-form-question">{data.question}</div>
  {#each agentList as agent}
    {#if data.agents.filter((obj) => obj.name == agent.name).length > 1}
      <div transition:slide|local class="question-form-agents" on:click={() => dispatch("agentView", agent.name)}>
        {agent.name}
      </div>
    {:else}
      <div
        transition:slide|local
        class="question-form-agents"
        on:click={() =>
          dispatch(
            "message",
            data.whatsapps.find((obj) => obj.id == agent.id)
          )}
      >
        {agent.name}
      </div>
    {/if}
  {/each}
</div>
