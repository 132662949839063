<script>
  import { createEventDispatcher } from "svelte";
  import axios from "axios";
  import ChatTextBox from "./chat/ChatTextBox.svelte";
  import ChatBubbleBot from "./chat/ChatBubbleBot.svelte";
  import ChatBubbleUser from "./chat/ChatBubbleUser.svelte";
  import Button from "./base/Button.svelte";
  import Closer from "./base/Closer.svelte";
  import { getHost } from "../utils/sendwhatsapp_rewrite_chabi";
  import { v4 as uuidv4 } from "uuid";

  const uuid = uuidv4();

  export let data;
  export let activePerson;

  $: chatList = [{ id: 1, botText: data.faqsChatBotQuestionText ?? "Hello, how can I help you?", userText: "" }];
  let faqs = [];
  let isSolved = false;

  $: fullScreenMobile = data.expandFullScreenMobile;

  const dispatch = createEventDispatcher();

  async function setVal(v) {
    let objDiv = document.getElementById("messageBox");
    objDiv.scrollTop = objDiv.scrollHeight;
    if (v.detail.trim() == "") return;
    let text = v.detail;
    let param = {
      search: v.detail.trim(),
      previous: chatList[chatList.length - 1],
    };

    param["whatsapp"] = activePerson.id;
    param["conversation"] = uuid;

    // show user text in chat just after user sends it
    chatList[chatList.length - 1].userText = text;

    await axios
      .post(getHost() + "/api/searchfaq-ai", param)
      .then((response) => {
        faqs = response.data;

        // update user text with the answer
        chatList[chatList.length - 1].userText = text;

        if (faqs && faqs.relevance && faqs.answer && faqs.answer.relevance !== 0) {
          chatList.push({
            id: chatList.length + 1,
            botText: faqs.answer,
            userText: "",
          });

          isSolved = true;
        } else {
          chatList.push({
            id: chatList.length + 1,
            botText: data.faqsChatBotNotSolvedText ?? "Sorry, I don't have an answer for that, what else can I help you with?",
            userText: "",
          });
          isSolved = false;
          animateWhatsAppButton();
        }

        // if (faqs.extra_question){
        //   //setTimeout(() => {
        //     chatList.push({
        //       id: chatList.length+ 1,
        //       botText: faqs.extra_question,
        //       userText: ""
        //     });
        //   //}, 1000)
        // }
        let objDiv = document.getElementById("messageBox");
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((error) => {
        alert(error);
      });
  }

  const animateWhatsAppButton = () => {
    let whatsappBtn = document.getElementById("whatsapp-btn");
    whatsappBtn.classList.add("shake-btn");
    setTimeout(() => {
      whatsappBtn.classList.remove("shake-btn");
    }, 2000);
  };
</script>

<div class="chatwith-chat chatwith-faqschat" class:fullScreenMobile>
  <div class="chatwith-chat-panel">
    <div class="chatwith-chat-content">
      {#if data.widgetType !== "bigperson"}
        <Closer on:close={() => dispatch("problem-solved")} />
      {/if}
      <div class="chatwith-chat-messages" id="messageBox">
        {#each chatList as element, i (element.id)}
          <div class="scale-in-left">
            <ChatBubbleBot text={element.botText} showDot={true} />
          </div>
          {#if element.userText}
            <div class="scale-in-right">
              <ChatBubbleUser bubbleData={{ val: element.userText }} />
            </div>
          {/if}
        {/each}
      </div>
      {#if data.widgetType === "bigperson"}
        <div class="chatwith-chat-footer-bigperson">
          <ChatTextBox on:message={setVal} fullScreenMobile={data.expandFullScreenMobile} translateChatAnswer={data.translateChatAnswer} />
          <div class="chatwith-faqschat-buttons">
            {#if activePerson.online === true}
              <Button on:click={() => dispatch("problem-not-solved")} buttonText="WhatsApp" showIcon={true} id="whatsapp-btn" />
            {:else}
              <Button on:click={() => dispatch("leave-message")} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

{#if data.widgetType !== "bigperson"}
  <div class="chatwith-chat " style="background: #F0F0F0;">
    <div class="chatwith-chat-footer">
      <ChatTextBox
        on:message={setVal}
        hasBorder={data.widgetType === "bigperson"}
        fullScreenMobile={data.expandFullScreenMobile}
        translateChatAnswer={data.translateChatAnswer}
      />
      <div class="chatwith-faqschat-buttons">
        {#if activePerson.online === true}
          <Button on:click={() => dispatch("problem-not-solved")} buttonText="WhatsApp" showIcon={true} id="whatsapp-btn" />
        {:else}
          <Button on:click={() => dispatch("leave-message")} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
        {/if}
      </div>
    </div>
  </div>
{/if}
