<script>
    import { fade } from "svelte/transition";
  
    export let popupInfo;
  
    $: positionRight = popupInfo?.position === 'right';
    $: showPopup = !closePopup && popupInfo?.activePopUp && (screenWidth > 768 ? popupInfo?.displayOnLargeScreens : popupInfo?.displayOnSmallScreens);
  
    let screenWidth;
    let closePopup = false;
  
    const handleClose = () => {
      closePopup = true;
    }
  
    const handleClick = () => {
      popupInfo?.url && window.open(popupInfo?.url, popupInfo?.urlOpenNewTab ? '_blank' : '_self'); 
    }
  
  </script>
  <svelte:window bind:innerWidth={screenWidth}/>
  {#if showPopup}
    <div on:click={handleClick} class="chatwith-notification" class:positionRight in:fade={{ delay: 1000 }} out:fade={{ delay: 100 }} style={popupInfo?.url && 'cursor: pointer;'}>
      <div on:click|stopPropagation={handleClose} class="close-chatwith" in:fade={{ delay: 100 }}>
        <svg aria-hidden="true" focusable="false" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"
          ><path
            fill="#c8c8c8"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
          /></svg
        >
      </div>
      {#if popupInfo.iconUrl}
      <div class="chatwith-notification-icon">
        <img class="chatwith-notification-img" alt="" src={popupInfo.iconUrl} />
      </div>
      {/if}
      <div class="chatwith-notification-wrapper" style={!popupInfo?.iconUrl && 'padding-left: 5px;'}>
        <div class="chatwith-notification-title">
          {popupInfo.title}
        </div>
        <div class="chatwith-notification-description">
          {popupInfo.description}
        </div>
      </div>
    </div>
  {/if}
  