const translations = {
  days: {
    en: "DAYS",
    es: "DÍAS",
  },
  hours: {
    en: "HOURS",
    es: "HORAS",
  },
  minutes: {
    en: "MINUTES",
    es: "MINUTOS",
  },
  seconds: {
    en: "SECONDS",
    es: "SEGUNDOS",
  },
  last_places_available: {
    en: "Last places available",
    es: "Últimas plazas disponibles",
  },
  male: {
    en: "Male",
    es: "Masculino",
    fr: "Mâle",
    it: "Maschio",
    de: "Männlich",
    pt: "Macho",
    ch: "男性",
    ru: "мужской",
    my: "lelaki",
    hi: "पुरुष"
  },
  female: {
    en: "Female",
    es: "Femenino",
    fr: "Femelle",
    it: "Femmina",
    de: "Weiblich",
    pt: "Fêmea",
    ch: "女性",
    ru: "женский",
    my: "perempuan",
    hi: "महिला"
  },
  other_gender: {
    en: "Other",
    es: "Otro",
    fr: "Autre",
    it: "Altro",
    de: "Andere",
    pt: "Outro",
    ch: "其他",
    ru: "другой",
    my: "lain",
    hi: "अन्य"
  },
  noAvailabilityToday: {
    en: "No available today",
    es: "No disponible hoy",
    fr: "Pas disponible aujourd"
  },
  availableAt: {
    en: "Available in",
    es: "Disponible en",
    fr: "Disponible en",
  },
  sentMessage: {
    en: "Your message has been sent",
    es: "Tu mensaje ha sido enviado",
    fr: "Votre message a été envoyé",
  }
}

export default translations;
