<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	let video;
	let currentTime = 0;
	let duration;

	let classVolumeOn = "chatwith-volume-show";
	let classVolumeOff = "chatwith-volume-hide";
	let muted = false;
	let paused = true;

	export let data;
	export let isReturnUser;

	$: fullScreenMobile = data.expandFullScreenMobile;

	let timer = setTimeout(() => {
		if(duration) video.play();
	}, 300);

  let loadTimer = setTimeout(() => {
		video.play();
	}, 600);

	const switchVolume = () =>{
		muted = !muted;
		[classVolumeOn, classVolumeOff] = [classVolumeOff, classVolumeOn];
	};

	const repeat = () => {
		currentTime = 0;
		video.play();
	}

	const close = (toChat) => {
		clearInterval(timer);
		clearInterval(loadTimer);
		dispatch("close", toChat);
	}

	const  format = seconds => {
		if (isNaN(seconds)) return '...';

		const minutes = Math.floor(seconds / 60);
		seconds = Math.floor(seconds % 60);
		if (seconds < 10) seconds = '0' + seconds;

		return `${minutes}:${seconds}`;
	}
	function handleMove(e) {
		if (!duration) return; // video not loaded yet
		if (e.type !== 'touchmove' && !(e.buttons & 1)) return; // mouse not down

		const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
		const { left, right } = this.getBoundingClientRect();
		currentTime = duration * (clientX - left) / (right - left);
	}

</script>

<div class:fullScreenMobile class="chatwith-video"  >
	{#if paused}
	<div class="start-screen-container z-index-10000">
		<div class="pause-screen enter-transition">
			<svg on:click="{() => repeat()}" class="start-icon" width="94px" height="94px"  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 481 481" style="enable-background:new 0 0 481 481;" xml:space="preserve">
				<g>
					<g>
						<path fill={data.color} d="M410.6,70.4C365.1,25,304.7,0,240.5,0S115.9,25,70.4,70.4C25,115.9,0,176.3,0,240.5s25,124.6,70.4,170.1
							C115.8,456,176.2,481,240.5,481s124.6-25,170.1-70.4C456,365.2,481,304.8,481,240.5S456,115.9,410.6,70.4z M240.5,454
							C122.8,454,27,358.2,27,240.5S122.8,27,240.5,27S454,122.8,454,240.5S358.2,454,240.5,454z"/>
						<path fill={data.color} d="M349.2,229.1l-152.6-97.9c-4.2-2.7-9.4-2.9-13.8-0.5c-4.3,2.4-7,6.9-7,11.8v195.7c0,4.9,2.7,9.5,7,11.8
							c2,1.1,4.3,1.7,6.5,1.7c2.5,0,5.1-0.7,7.3-2.1l152.6-97.9c3.9-2.5,6.2-6.8,6.2-11.4S353,231.6,349.2,229.1z M202.8,313.7V167.3
							l114.1,73.2L202.8,313.7z"/>
					</g>
				</g>
			</svg>
		</div>
	</div>
	{/if}
	<div class="chatwith-video-controls" style="opacity: {duration ? 1 : 0}">
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="32" height="32"
			on:click="{() => repeat()}"
			viewBox="0 0 24 24"
			transform="scale(-1 -1)"
			style=" fill:#ffffff;">
			<path d="M 2 2 L 4.9394531 4.9394531 C 3.1262684 6.7482143 2 9.2427079 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 6.486 17.514 2 12 2 L 12 4 C 16.411 4 20 7.589 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 9.7940092 4.9004767 7.7972757 6.3496094 6.3496094 L 9 9 L 9 2 L 2 2 z"></path>
		</svg>
		<div class="chatwith-video-info">
			<progress value="{(currentTime / duration) || 0}"/>
			<span class="time">{format(currentTime)} / {format(duration)}</span>
		</div>
		<svg xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			width="32" height="32"
			on:click="{() => switchVolume()}"
			viewBox="0 0 518.518 518.518"
			style="enable-background:new 0 0 518.518 518.518;fill:#ffffff;">
			<path d="M253.674,80.899L90.27,197.638H26.392C11.857,197.638,0,209.495,0,224.03v70.457c0,14.534,11.857,26.392,26.392,26.392
				H90.27l163.557,116.739c11.857,8.491,21.419,3.52,21.419-11.016V91.915C275.246,77.38,265.607,72.407,253.674,80.899z"/>
			<path  class={classVolumeOn}  d="M342.338,81.97c-12.164,0-22.033,9.869-22.033,22.032s9.869,22.032,22.033,22.032c72.98,0,132.115,59.135,132.115,132.116
				c0,72.981-59.135,132.115-132.115,132.115c-12.164,0-22.033,9.869-22.033,22.032s9.869,22.032,22.033,22.032
				c97.307,0,176.18-78.872,176.18-176.18C518.518,160.918,439.723,81.97,342.338,81.97z"/>
			<path  class={classVolumeOn}  d="M342.338,313.153c-12.164,0-22.033,9.868-22.033,22.031c0,12.164,9.869,22.032,22.033,22.032
				c54.773,0,99.066-44.37,99.066-99.067c0-54.698-44.369-99.067-99.066-99.067c-12.164,0-22.033,9.868-22.033,22.032
				s9.869,22.032,22.033,22.032c30.369,0,55.08,24.633,55.08,55.08C397.418,288.673,372.785,313.153,342.338,313.153z"/>

			<path class={classVolumeOff} d="M452.115,260.329l62.5-62.501c5.201-5.202,5.201-13.846,0-19.048l-12.623-12.623c-5.201-5.202-13.846-5.202-19.049,0
			l-62.5,62.5l-62.041-62.042c-5.201-5.202-13.846-5.202-19.049,0l-12.623,12.623c-5.201,5.202-5.201,13.847,0,19.048l62.043,62.042
			L326.73,322.37c-5.201,5.202-5.201,13.847,0,19.049l12.623,12.622c5.203,5.202,13.848,5.202,19.049,0L420.443,292l62.041,62.041
			c5.203,5.202,13.848,5.202,19.049,0l12.623-12.622c5.201-5.202,5.201-13.847,0-19.049L452.115,260.329z"/>
		</svg>
	</div>
	<video
		src={isReturnUser && data.returningVideoSplashUrl?data.returningVideoSplashUrl:data.videoSplashUrl}
		bind:currentTime
		bind:duration
		bind:muted
		bind:paused
		bind:this={video}
		on:mousemove={handleMove}
		playsinline
	>
		<track kind="captions"/>
	</video>


	<div class="chatwith-video-close" style="opacity: {duration ? 1 : 0}">
		<svg xmlns="http://www.w3.org/2000/svg"
			on:click="{() => close()}"
			viewBox="0 0 24 24" width="32px" height="32px" style="fill:black">
			<circle cx="12" cy="12" r="10" style="fill:white"/>
			<path d="M14.812,16.215L7.785,9.188c-0.384-0.384-0.384-1.008,0-1.392l0.011-0.011c0.384-0.384,1.008-0.384,1.392,0l7.027,7.027 c0.384,0.384,0.384,1.008,0,1.392l-0.011,0.011C15.82,16.599,15.196,16.599,14.812,16.215z"/><path d="M7.785,14.812l7.027-7.027c0.384-0.384,1.008-0.384,1.392,0l0.011,0.011c0.384,0.384,0.384,1.008,0,1.392l-7.027,7.027 c-0.384,0.384-1.008,0.384-1.392,0l-0.011-0.011C7.401,15.82,7.401,15.196,7.785,14.812z"/>
		</svg>
	</div>

	<div class="chatwith-video-close" style="opacity: {duration ? 1 : 0}">
		<svg xmlns="http://www.w3.org/2000/svg"
			on:click="{() => close(false)}"
			viewBox="0 0 24 24" width="32px" height="32px" style="fill:black">
			<circle cx="12" cy="12" r="10" style="fill:white"/>
			<path d="M14.812,16.215L7.785,9.188c-0.384-0.384-0.384-1.008,0-1.392l0.011-0.011c0.384-0.384,1.008-0.384,1.392,0l7.027,7.027 c0.384,0.384,0.384,1.008,0,1.392l-0.011,0.011C15.82,16.599,15.196,16.599,14.812,16.215z"/><path d="M7.785,14.812l7.027-7.027c0.384-0.384,1.008-0.384,1.392,0l0.011,0.011c0.384,0.384,0.384,1.008,0,1.392l-7.027,7.027 c-0.384,0.384-1.008,0.384-1.392,0l-0.011-0.011C7.401,15.82,7.401,15.196,7.785,14.812z"/>
		</svg>
	</div>
	{#if data.videoAgent}
	<div class="chatwith-video-closebutton" style="opacity: {duration ? 1 : 0};">
		<button on:click={() => close(true)}>
			{#if (data.videoAgentButtonText)}
			{data.videoAgentButtonText}
			{:else}
			Let's chat!
			{/if}
  		</button>
	</div>
	{/if}
</div>
