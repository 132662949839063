import { isClosedConversationStore } from "../store";
import {dataObj} from "./getdata";
import { dataAttributes } from './getdata'
import { sendBookingDataToGA } from './sendBookingToGA'

function tagging(person){
    let event = 'chatwith';

    isClosedConversationStore.subscribe(value => {
      if (value) {
        event = 'CW_LeadClose';
      };
    });

    let extras = {}

    extras =  {
        'widget': person.businessGaTag?person.businessGaTag:'whatapp',
        'agent': person.gaTag?person.gaTag : person.id
    }

    if (typeof window.fbq === 'function') {
        //console.log("fb")
        window.fbq('trackCustom', 'chatWithEvent', extras);
    }

    if (typeof window.gtag === "function") {
        //console.log("GA 1")
        window.gtag('event', event, {
            'event_category': person.businessGaTag?person.businessGaTag:'whatapp',
            'event_label': person.gaTag?person.gaTag : person.id
        });

        // console.log("gtag detected")

        if(dataObj && dataObj.googleAdsCampaign && dataObj.googleAdsCampaign.conversionCode){
            // console.log("dataObj",dataObj.googleAdsCampaign.conversionCode)
            window.gtag('event', 'conversion', {'send_to': dataObj.googleAdsCampaign.conversionCode});
        }

    }
    else if (typeof window.dataLayer === 'object'){
        // console.log("GA dataLayer ")
        window.dataLayer.push({
            'event': event,
            'cw_event_category': person.businessGaTag?person.businessGaTag:'whatsapp',
            'cw_event_action': 'chatwith',
            'cw_event_label': person.gaTag?person.gaTag : person.id
        });
    }

    sendBookingDataToGA()
}

export { tagging };
