<script>
  export let data
  export let isReturnUser

  $: fullScreenMobile = data.expandFullScreenMobile
  $: countDownEnabled = data.countDownEnabled
</script>

<div class="chatwith-banner" class:fullScreenMobile class:countDownEnabled>
  {#if !data.outCloseButton}
    {#if isReturnUser}
      {#if data.returningBannerUrl !== null && data.activateReturningBanner === true}
        {#if data.widgetTheme === 'bubbleTheme' && !data.noHeader}
          <div class="chatwith-banner-round" />
        {/if}
        {#if data.returningBannerLink !== null}
          <a
            class="chatwith-banner-link"
            href={data.returningBannerLink}
            target={data.returningBannerSelf ? '_blank' : '_self'}>
            <img
              class="chatwith-banner-img"
              alt="Banner"
              src={data.returningBannerUrl} />
          </a>
        {:else}
          <img
            class="chatwith-banner-img"
            alt="Banner"
            title="No available link"
            src={data.returningBannerUrl} />
        {/if}
      {/if}
    {:else if data.bannerUrl !== null && data.ActivateBanner === true}
      {#if data.widgetTheme === 'bubbleTheme' && !data.noHeader}
        <div class="chatwith-banner-round" />
      {/if}
      {#if data.bannerLink !== null}
        <a
          class="chatwith-banner-link"
          href={data.bannerLink}
          target={data.bannerSelf ? '_blank' : '_self'}>
          <img class="chatwith-banner-img" alt="Banner" src={data.bannerUrl} />
        </a>
      {:else}
        <img
          class="chatwith-banner-img"
          alt="Banner"
          title="No available link"
          src={data.bannerUrl} />
      {/if}
    {/if}
  {/if}
</div>
