<script>
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";
  import axios from "axios";
  import { getHost } from "../../utils/sendwhatsapp_rewrite_chabi";

  const dispatch = createEventDispatcher();

  export let bgcolor;
  export let onboardPaymentLink;
  export let name;
  export let phoneNumber;
  export let address;
  export let email;

  let isActive = onboardPaymentLink.active;
  let stripeReady = false;
  let mounted = false;
  let stripe;
  let card;
  let cvc;
  let exp;
  let isPending = false;
  let isSuccess = false;
  let isError = false;
  let apiUrl = getHost();

  onMount(() => {
    mounted = true;
    if (stripeReady) {
      loadStripeElements();
    }
  });

  function stripeLoaded() {
    stripeReady = true;
    if (mounted) {
      loadStripeElements();
    }
  }

  function loadStripeElements() {
    stripe = Stripe(onboardPaymentLink.publicKey, {
      stripeAccount: onboardPaymentLink.stripeAccount,
    });
    const elements = stripe.elements();
    const style = {
      base: {
        color: "#32325d",
        lineHeight: "45px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aaa",
        },
        height: "45px",
      },
      invalid: {
        color: "#ff0000",
        iconColor: "#ff0000",
      },
    };

    // Card number
    card = elements.create("card", {
      showIcon: true,
      placeholder: "Card Number",
      style: style,
    });
    card.mount("#card");
    card.on("change", showCardError);
  }

  function showCardError(event) {
    let isErrorDiv = document.getElementById("card-errors");
    if (event.error) {
      isErrorDiv.textContent = event.error.message;
      isError = true;
    } else {
      isErrorDiv.textContent = "";
      isError = false;
    }
  }

  export async function submit() {
    let client_secret;

    try {
      await axios
        .post(apiUrl + "/api/payment-active/" + onboardPaymentLink.link, {})
        .then((response) => {
          if (!response.data.active) {
            isActive = false;
          }
        })
        .catch((error) => {
          isActive = false;
        });

      if (!isActive) return;

      isPending = true;
      isError = false;

      await axios
        .post(apiUrl + "/api/connect-payment-intent/" + onboardPaymentLink.link, {
          name: name ? name : "",
          phone: phoneNumber ? phoneNumber : "",
          address: address ? address : "",
          email: email ? email : "",
        })
        .then((response) => {
          client_secret = response.data.client_secret;
        })
        .catch((error) => {
          client_secret = false;
        });
    } catch (ex) {
      client_secret = false;
    }
    isPending = false;

    if (!client_secret) {
      showCardError({ error: { message: "Client secret not found." } });
      return;
    }

    await stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: card,
        },
      })
      .then(function (result) {
        if (result.error) {
          showCardError(result);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            isSuccess = true;
            dispatch("success");
          }
        }
      });
  }
</script>

<svelte:head>
  <script src="https://js.stripe.com/v3/" on:load={stripeLoaded}></script>
</svelte:head>
<div class="chatwith-stripe">
  <div class="chatwith-card" id="card">
    <!-- Elements will create input elements here -->
  </div>
  <div class="chatwith-box-error" id="card-errors" role="alert" style="display:{isError ? 'block' : 'none'}">
    <!-- We'll put the error messages in this element -->
  </div>
  <div class="chatwith-card-image">
    <img src={"process.env.CDN_HOST" + "/img/accepted-cards.png"} alt="" />
  </div>
  {#if isPending}
    <div id="info">Please wait while your card is being processed</div>
  {/if}

  {#if !isActive}
    <div class="chatwith-inactive">Your payment has not been activated.</div>
  {/if}

  {#if isSuccess}
    <div class="chatwith-success" id="success-banner" style="background-color:{bgcolor}">Your payment has been successful</div>
  {/if}
</div>
