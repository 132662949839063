import App from "./App.svelte";

if (document.currentScript.hasAttribute("data-widget-type")) {
    const app = new App({
        target: document.getElementById("whatapp-people-widget"),
        props: {
            widgetType: "bigperson",
            injectObject: eval(document.currentScript.getAttribute("data-inject-object")),
            cookieOverride: eval(document.currentScript.getAttribute("data-cookie-override")),
            dataActive: eval(document.currentScript.getAttribute("data-active")),
            dataHeader: eval(document.currentScript.getAttribute("data-header")),
            src: document.currentScript.getAttribute("src"),
        },
    });
} else if (document.currentScript.hasAttribute("data-widget-embed")) {
    const app = new App({
        target: document.getElementById("data-widget-embed"),
        props: {
            widgetType: "classic",
            injectObject: eval(document.currentScript.getAttribute("data-inject-object")),
            cookieOverride: eval(document.currentScript.getAttribute("data-cookie-override")),
            alwaysActive: true,
            src: document.currentScript.getAttribute("src"),
        },
    });
} else if (document.currentScript.hasAttribute("data-widget-embed-google")) {
    const app = new App({
        target: document.getElementById("data-widget-embed-google"),
        props: {
            widgetType: "classic",
            injectObject: eval(document.currentScript.getAttribute("data-inject-object")),
            cookieOverride: eval(document.currentScript.getAttribute("data-cookie-override")),
            embed: true,
            alwaysActive: true,
            src: document.currentScript.getAttribute("src"),
        },
    });
} else {
    const app = new App({
        target: document.body,
        props: {
            widgetType: "classic",
            injectObject: eval(document.currentScript.getAttribute("data-inject-object")),
            cookieOverride: eval(document.currentScript.getAttribute("data-cookie-override")),
            dataActive: eval(document.currentScript.getAttribute("data-active")),
            src: document.currentScript.getAttribute("src"),
        },
    });
}

export default app;
