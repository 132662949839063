<script>
  import { dayjs } from "svelte-time";
  import { fade } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  import BackButton from "./BackButton.svelte";

  const dispatch = createEventDispatcher();

  export let dateHeading;
  export let data;
  export let dayStore;
  export let bookingConfig;

  $: selectedItem = -1;

  const handleSelect = (index) => {
    selectedItem = index;
  };
  const handleConfirm = (index) => {
    dispatch("confirm", index);
  };

  const getStartTime = (str) => {
    if (bookingConfig.use24HourTimeFormat) {
      return dayjs(str.substr(0, 25)).format("HH:mm");
    } else {
      return dayjs(str.substr(0, 25)).format("hh:mm");
    }
  };

  const getStartTimeNoon = (str) => {
    if (bookingConfig.use24HourTimeFormat) {
      return "";
    } else {
      return dayjs(str.substr(0, 25)).format(" A");
    }  
  };

  console.log({ dayStore });
</script>

<div class="chatwith-booking-scheduler" transition:fade={{ duration: 125 }}>
  <div class="chatwith-booking-back" on:click={() => dispatch("back")}>
    <BackButton />
  </div>

  <div class="chatwith-booking-scheduler-heading">{dateHeading}</div>
  <div class="chatwith-booking-scheduler-title">
    <!-- {data.translateSelectDateAndField ?? "Select a time"} -->
  </div>
  <div class="chatwith-booking-times">
    {#each dayStore as item, i}
      {#if selectedItem === i}
        <div class="chatwith-booking-time-wrapper">
          <div class="chatwith-booking-time-selected">
            <div>{getStartTime(item.time)}<b>{getStartTimeNoon(item.time)}</b></div>
          </div>
          <div class="chatwith-booking-time-confirm" on:click={() => handleConfirm(i)}>
            {data.translateConfirm ?? "Confirm"}
          </div>
        </div>
      {:else}
        <div class="chatwith-booking-time" on:click={() => handleSelect(i)}>
          <div>{getStartTime(item.time)}<b>{getStartTimeNoon(item.time)}</b></div>
        </div>
      {/if}
    {/each}
  </div>
  {#if dayStore.length > 0}
    <div class="chatwith-booking-gmt">{`GMT + ${dayStore[0].time.substr(20, 5)}`}</div>
  {/if}
</div>
