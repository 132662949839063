<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import FormValidate from "./FormValidate.svelte";
  import Closer from "./base/Closer.svelte";
  import TelField from "./base/TelField.svelte";
  import Button from "./base/Button.svelte";
  import { toArray, getWrittenName, isIPhone, splitNameAndPrice, fetchLocation, checkIfSomeItemCloseConversation } from "../utils/utilities";
  import Spinner from "./base/Spinner.svelte";
  import { fileUpload } from "../utils/sendwhatsapp_rewrite_chabi";
  import { isClosedConversationStore } from "../store";
  import Toast from "./base/Toast.svelte";
  import { currentLanguage } from "../store";
  import translations from "../translations";
  import { getFormFromLocalStorage, saveFormToLocalStorage } from "../utils/localStorage";

  const dispatch = createEventDispatcher();

  // --- Form and state variables ---
  let errorList = [];
  let formValidateList = [];
  let showBooking = false;
  let isLoading = false;
  let cookieModalExist = eval("typeof modalPrivacityTermsCookies") === "function";
  let currentLang = "en";
  let filteredNumber;

  export let formitems;
  export let data;
  export let noclose = false;
  export let person;
  export let isBigperson = false;

  $: fullScreenMobile = data.expandFullScreenMobile;
  $: notQualified = false;
  $: isSent = false;
  $: sendMessage = data.transSuccessMessage ?? translations.sentMessage[currentLang];

  let isClosedConversation = false;

  let filtered = formitems.items.map((item) => item.fieldToFilterFrom);
  filteredNumber = filtered.filter((item) => item).length;
  formitems.items.filter((item) => item.type !== "freetext").forEach((a) => (a.val = ""));

  onMount(() => {
    // Subscribe to changes in currentLanguage
    currentLanguage.subscribe((value) => {
      currentLang = value;
    });

    formitems.items.forEach((item, i) => {
      if (item.type === "selectBooking") {
        let now = new Date();
        let timeZoned = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
        item.dateVal = timeZoned.toISOString().slice(0, 16);
      }
    });
  });

  if (person?.bookingConfigs?.length > 0 && isBigperson) {
    showBooking = true;
  }

  const handleFileSelected = (e, i) => {
    formitems.items[i].val = e.target.files[0];
  };

  // function to check if the zip code is valid and fetch the location data
  const handlePostalCodeBlur = async (postalCode) => {
    const locationData = await fetchLocation(postalCode, data.country);

    if (locationData) {
      const { city, province } = locationData;

      // create or update the city field
      let cityField = formitems.items.find((item) => item.label === "City");
      if (!cityField) {
        cityField = { label: "City", value: city, type: "hidden", required: false };
        formitems.items.push(cityField);
      } else {
        cityField.value = city;
      }

      // create or update the province field
      let provinceField = formitems.items.find((item) => item.label === "Province");
      if (!provinceField) {
        provinceField = { label: "Province", value: province, type: "hidden", required: false };
        formitems.items.push(provinceField);
      } else {
        provinceField.value = province;
      }

      await tick(); // Prevents a Svelte warning about updating the DOM during rendering
    }
  };

  const sendtocallme = async (url = null) => {
    if (isLoading) {
      return;
    }
    if (showBooking) {
      dispatch("booking");
      return;
    }

    for (let i in formValidateList) {
      try {
        formValidateList[i].validate();
      } catch (ex) {
        //console.log(ex);
      }
    }

    if (errorList.length == 0) {
      for (let i in formitems.items) {
        if (formitems.items[i].type === "file" && formitems.items[i].val && !filtered[i]) {
          isLoading = true;
          const res = await fileUpload(formitems.items[i].val, person.id);
          formitems.items[i].val = res?.data?.id;
        }
        if (formitems.items[i].type === "hidden") {
          formitems.items[i].val = formitems.items[i].value ?? formitems.items[i].hval;
        }
        if (formitems.items[i].type === "selectBooking") {
          formitems.items[i].val = formitems.items[i].selectVal;
        }
        if (formitems.items[i].type === "selectBookingFullDay") {
          formitems.items[i].val = formitems.items[i].selectVal;
        }
      }
      isLoading = false;
      let scoreCnt = 0,
        scoreSum = 0,
        score;
      if (person.form.activateScoringFilter) {
        for (let item of formitems.items) {
          if (item.type === "select") {
            score = item.dataObject.find((obj) => obj.value === item.val)?.score;
            switch (score) {
              case "gold":
                scoreSum += 3;
                break;
              case "silver":
                scoreSum += 2;
                break;
              case "bronze":
                scoreSum += 1;
                break;
            }
            scoreCnt++;
          }
        }
      }

      // save form data to local storage for prefilling
      if (data.saveDataInWidget) {
        let formData = formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]);
        saveFormToLocalStorage(data.id, formData);
      }

      notQualified =
        (scoreCnt > 0 && scoreSum / scoreCnt < 2) ||
        (person.online === false && data.offlineLeads === true) ||
        (data.desktopLeads === true && window.innerWidth > 1000);

      dispatch("callmex", {
        data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
        url: url,
        notQualified: notQualified,
      });

      if (!checkIfSomeItemCloseConversation(formitems.items)) {
        sendMessage = data.transSuccessMessage ?? translations.sentMessage[currentLang];
      }

      isSent = true;

      setTimeout(() => {
        isSent = false;
      }, 3500);

      clearForm();
    }
  };

  const handleKeyPress = (event) => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
  };

  const handleBlur = async (event, index) => {
    if (isClosedConversation) {
      return;
    }
    if (event.code == "Enter") {
      event.preventDefault();
    }
    formitems.items.forEach((item, i) => {
      checkFilter(i);
    });

    // Si el campo es de tipo "zipcode", llama a handlePostalCodeBlur
    if (formitems.items[index].type === "zipcode") {
      await handlePostalCodeBlur(formitems.items[index].val);
    }

    if (formitems.items[index].type === "select") {
      const option = formitems.items[index].dataObject.find((obj) => obj.value === formitems.items[index].val);
      if (option.closeConversation) {
        sendMessage = option.closeConversationMessage;
      }
    }
    checkNewVisibleFields(formitems.items[index]);
  };

  let isDateInput = Array(person.form.items.length).fill(false);
  let isTimeInput = Array(person.form.items.length).fill(false);

  function onDateFocus(index) {
    isDateInput[index] = true;
  }

  function onDateBlur(index) {
    isDateInput[index] = false;
  }

  function onTimeFocus(index) {
    isTimeInput[index] = true;
  }

  function onTimeBlur(index) {
    isTimeInput[index] = false;
  }

  const checkFilter = (index) => {
    if (index < 0) {
      return false;
    }
    const item = formitems.items[index];
    if (!item.fieldToFilterFrom) {
      return false;
    }
    const values = item.valueToFilterFrom.split("|");

    const checkItem = formitems.items[item.fieldToFilterFrom - 1];
    let isMatch = true;
    if (filtered[item.fieldToFilterFrom - 1] !== true) {
      values.forEach((value) => {
        if (checkItem.type === "checkbox") {
          if (checkItem.val === (value === 1 || value === "1")) {
            isMatch = false;
          }
        } else if (value.trim() === checkItem.val.trim()) {
          isMatch = false;
        }
      });
    }

    filtered[index] = isMatch;
    return isMatch;
  };

  const clearForm = () => {
    formitems.items.forEach((item) => {
      item.val = "";
    });
  };

  const prefillForm = (formId) => {
    if (data.saveDataInWidget) {
      const savedData = getFormFromLocalStorage(formId);
      if (savedData) {
        formitems.items.forEach((item, i) => {
          const savedItem = savedData.find((saved) => saved.id === item.id);
          if (savedItem) {
            item.val = savedItem.val;
            if (item.type === "tel") {
              item.telNum = savedItem.telNum;
              item.telCode = savedItem.telCode;
            }
            if (item.type === "zipcode") {
              handlePostalCodeBlur(savedItem.val);
            }
          }
        });
      }
    }
  };

  prefillForm(data.id);

  function selectOption(data, item, element) {
    item.val = data;

    // check if has to close the conversation
    const option = item.dataObject.find((obj) => obj.value === item.val);
    if (option.closeConversation) {
      sendMessage = option.closeConversationMessage;
    }

    Array.from(element.parentElement.childNodes)
      .filter((el) => el.nodeType === Node.ELEMENT_NODE) // filter out text nodes
      .forEach((el) => {
        el.classList.remove("selected");
      });

    element.classList.add("selected");

    formitems.items.forEach((_, i) => {
      checkFilter(i);
    });

    checkNewVisibleFields(item);
  }

  function replaceURLsWithLinks(text) {
    // Regular expression to match URLs outside of <a> tags
    const urlRegex = /(?<!<a\s+[^>]*?href=["'])https?:\/\/[^\s<]+(?![^<]*<\/a>)/g;

    // Replace URLs outside of <a> tags with <a> tags
    return text.replace(urlRegex, (url) => {
      return `<br/><br/><b><a href="${url}" target="_blank">${url}</a></b><br/><br/>`;
    });
  }

  function scrollToNext(item) {
    tick().then(() => {
      let nextIndex = formitems.items.findIndex((i) => i.id === item.id) + 1;
      let nextElement = document.getElementById(formitems.items[nextIndex]?.id);
      if (nextElement) {
        nextElement.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  function checkNewVisibleFields(item) {
    if (filtered.filter((i) => i).length < filteredNumber) {
      // if there are new visible fields, scroll to next item to make sure the user sees them
      scrollToNext(item);
    }
    // update the number of visible fields
    filteredNumber = filtered.filter((item) => item).length;
  }
</script>

<div class:fullScreenMobile class="chatwith-form-wrapper">
  <div class="chatwith-form">
    <div style="padding-top: 20px">
      {#if noclose !== true}
        <Closer on:close={() => dispatch("close")} />
      {/if}
      {#if !showBooking}
        {#if formitems.info != ""}
          <div class="textito">{formitems.info}</div>
        {/if}
        {#each formitems.items as item, i}
          <div class:hidden={filtered[i]}>
            {#if item.type == "text"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="text"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? "*" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "file"}
              <div class="form-element">
                <p class="file-label">{item.label}</p>
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="file"
                  class={item.isError ? "error-border" : ""}
                  accept=".pdf, .docx, .xlsx, .jpg, .jpeg, .png, .zip"
                  on:change={(e) => handleFileSelected(e, i)}
                />
              </div>
            {:else if item.type == "number"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="number"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "url"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="url"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "date"}
              <div class="form-element">
                {#if isIPhone()}
                  {#if isDateInput[i]}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="date"
                      on:blur={() => onDateBlur(i)}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {:else}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="text"
                      on:focus={() => onDateFocus(i)}
                      placeholder={item.label}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {/if}
                {:else}
                  <input
                    id={item.id}
                    class="form-element-input {item.isError ? 'error-border' : ''}"
                    bind:value={item.val}
                    type="text"
                    onfocus="(this.type='date')"
                    placeholder={`${item.label}${item.required ? " *" : ""}`}
                    on:keypress={handleKeyPress}
                    on:change={(evt) => handleBlur(evt, i)}
                  />
                {/if}
              </div>
            {:else if item.type == "time"}
              <div class="form-element">
                {#if isIPhone()}
                  {#if isTimeInput[i]}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="time"
                      on:blur={() => onTimeBlur(i)}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {:else}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="text"
                      on:focus={() => onTimeFocus(i)}
                      placeholder={item.label}
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {/if}
                {:else}
                  <input
                    id={item.id}
                    class="form-element-input {item.isError ? 'error-border' : ''}"
                    bind:value={item.val}
                    type="text"
                    onfocus="(this.type='time')"
                    placeholder={`${item.label}${item.required ? " *" : ""}`}
                    on:keypress={handleKeyPress}
                    on:change={(evt) => handleBlur(evt, i)}
                  />
                {/if}
              </div>
            {:else if item.type == "select"}
              {#if !item.showAsButton}
                <div class="form-element">
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select id={item.id} bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as data}
                        <option value={data}>{getWrittenName(data, item?.dataObject)}</option>
                      {/each}
                    </select>
                  </div>
                </div>
              {:else}
                <div class="form-element">
                  <div class="chatwith-button-label">
                    {item.label}
                  </div>
                  <div class="chatwith-button-options">
                    {#each toArray(item.data) as data}
                      <button
                        type="button"
                        class="chatwith-option-button {item.val == data ? 'selected' : ''}"
                        on:click={(e) => {
                          selectOption(data, item, e.target);
                          item.val = data;
                        }}
                      >
                        {getWrittenName(data, item?.dataObject)}
                      </button>
                    {/each}
                  </div>
                  <input id={item.id} bind:value={item.val} type="hidden" />
                </div>
              {/if}
            {:else if item.type == "selectAndPrice"}
              {#if !item.showAsButton}
                <div class="form-element">
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select id={item.id} bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as itemData}
                        <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                      {/each}
                    </select>
                  </div>
                </div>
              {:else}
                <div class="form-element">
                  <div class="chatwith-button-label">
                    {item.label}
                  </div>
                  <div class="chatwith-button-options">
                    {#each toArray(item.data) as itemData}
                      <button
                        type="button"
                        class="chatwith-option-button {item.val == itemData ? 'selected' : ''}"
                        on:click={(e) => {
                          selectOption(itemData, item, e.target);
                          item.val = itemData;
                        }}
                      >
                        <div class="chatwith-option-button-text">
                          <span class="chatwith-option-button-name"
                            >{splitNameAndPrice(getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)).name}</span
                          >
                          {#if item?.showPrices}
                            <small class="chatwith-option-button-price"
                              >({splitNameAndPrice(getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)).price})</small
                            >
                          {/if}
                        </div>
                      </button>
                    {/each}
                  </div>
                  <input id={item.id} bind:value={item.val} type="hidden" />
                </div>
              {/if}
            {:else if item.type == "checkbox"}
              <div class="form-element">
                <div>
                  <label>
                    <input id={item.id} value={false} bind:checked={item.val} on:change={(evt) => handleBlur(evt, i)} type="checkbox" />
                    <p style="font-size: 12px">{item.label}</p>
                  </label>
                </div>
              </div>
            {:else if item.type == "hidden"}
              <div class="form-element">
                <span style="display: none">{item.label}</span>
                <input id={item.id} bind:value={item.value} type="hidden" />
              </div>
            {:else if item.type == "tel"}
              <div class="form-element">
                <TelField
                  {data}
                  bind:value={item.val}
                  bind:telNum={item.telNum}
                  bind:telCode={item.telCode}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  isError={item.isError}
                  on:message={(evt) => handleBlur(evt, i)}
                />
                <input id={item.id} bind:value={item.val} type="hidden" />
              </div>
            {:else if item.type == "zipcode"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="text"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? "*" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "gender"}
              <div class="form-element">
                <div class="chatwith-radio-buttons-label">
                  {item.label}
                </div>
                <div class="chatwith-radio-buttons-container">
                  <label>
                    <input
                      class="chatwith-radio-button"
                      type="radio"
                      name="gender-{i}"
                      bind:group={item.val}
                      value="M"
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                    {translations.male[currentLang]}
                  </label>
                  <label>
                    <input
                      class="chatwith-radio-button"
                      type="radio"
                      name="gender-{i}"
                      bind:group={item.val}
                      value="F"
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                    {translations.female[currentLang]}
                  </label>
                  <label>
                    <input
                      class="chatwith-radio-button"
                      type="radio"
                      name="gender-{i}"
                      bind:group={item.val}
                      value="Other"
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                    {translations.other_gender[currentLang]}
                  </label>
                </div>
                <input id={item.id} bind:value={item.val} type="hidden" />
              </div>
            {:else if item.type === "selectBooking"}
              <div class="form-element">
                <div class="chatwith-freebooking">
                  <!-- Select and Price -->
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select
                      id={item.id}
                      bind:value={item.selectVal}
                      class={item.isError ? "error-border" : ""}
                      on:change={(evt) => handleBlur(evt, i)}
                    >
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as itemData}
                        <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                      {/each}
                    </select>
                  </div>

                  <!-- Date input -->
                  <div class="form-element">
                    {#if isIPhone()}
                      {#if isDateInput[i]}
                        <input
                          class="form-element-input {item.isError ? 'error-border' : ''}"
                          bind:value={item.dateVal}
                          type="datetime-local"
                          on:change={(evt) => handleBlur(evt, i)}
                        />
                      {:else}
                        <input
                          class="form-element-input {item.isError ? 'error-border' : ''}"
                          bind:value={item.dateVal}
                          type="datetime-local"
                          on:change={(evt) => handleBlur(evt, i)}
                        />
                      {/if}
                    {:else}
                      <input
                        class="form-element-input {item.isError ? 'error-border' : ''}"
                        bind:value={item.dateVal}
                        type="datetime-local"
                        on:keypress={handleKeyPress}
                        on:change={(evt) => handleBlur(evt, i)}
                      />
                    {/if}
                  </div>
                </div>
              </div>
            {:else if item.type === "selectBookingFullDay"}
              <div class="form-element">
                <div class="chatwith-freebooking">
                  <!-- Select option -->
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select
                      id={item.id}
                      bind:value={item.selectVal}
                      class={item.isError ? "error-border" : ""}
                      on:change={(evt) => handleBlur(evt, i)}
                    >
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as itemData}
                        <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                      {/each}
                    </select>
                  </div>

                  <!-- Start Date input -->
                  <div class="form-element">
                    <div class="chatwith-input-label">Inicio</div>
                    <input
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.startDateVal}
                      type="date"
                      placeholder="Fecha de inicio"
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  </div>

                  <!-- End Date input -->
                  <div class="form-element">
                    <div class="chatwith-input-label">Fin</div>
                    <input
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.endDateVal}
                      type="date"
                      placeholder="Fecha de fin"
                      on:keypress={handleKeyPress}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  </div>
                </div>
              </div>
            {:else if item.type == "email"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="email"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "freetext" && item.hval}
              <div style="font-size: 13px !important;" class="form-element">
                {@html replaceURLsWithLinks(item.hval)}
              </div>
            {/if}
            {#if !filtered[i]}
              <FormValidate
                bind:value={errorList}
                bind:this={formValidateList[i]}
                on:error={(val) => {
                  item.isError = val.detail;
                }}
                {item}
                {data}
                index={i}
              />
            {/if}
            {#if item.type == "checkbox" && item.legalUrl}
              <div class="checkbox-link">
                {#if isBigperson && item.legalCanUseLocalModal && cookieModalExist}
                  {@html `<a style="font-size: 12px" target="_blank" onClick="return modalPrivacityTermsCookies('privacityPopup');">${
                    item.legalUrlText ?? "Click here to see the Privacy Policy"
                  }</a
                >`}
                {:else}
                  <a style="font-size: 12px" target="_blank" href={item.legalUrl}>{item.legalUrlText ?? "Click here to see the Privacy Policy"}</a>
                {/if}
              </div>
            {/if}
          </div>
        {/each}
      {/if}
      {#if formitems.buyLinks && formitems.buyLinks.length > 0 && !showBooking}
        {#each formitems.buyLinks as item, i}
          <Button
            id={"chatwith-buy-button" + i}
            on:click={() => sendtocallme(item.link)}
            buttonText={item.buttontext}
            showIcon={true}
            iconUrl={data.whatsappIconUrl}
            spanClassName={item.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
          />
        {/each}
      {:else}
        {#if isLoading}
          <Spinner />
        {/if}
        <Button
          on:click={() => sendtocallme(null)}
          buttonText={showBooking ? formitems.buttontext ?? "Book now" : formitems.buttontext}
          showIcon={true}
          iconUrl={data.whatsappIconUrl}
          spanClassName={formitems.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
          containerClassName={data.widgetType === "bigperson" ? "" : "fixed-bottom"}
        >
          {#if isSent}
            <Toast message={sendMessage} visible={isSent} />
          {/if}
        </Button>
      {/if}
    </div>
  </div>
</div>
