<script>
    export let data;
    export let agentsView;
    export let showRounded;
    export let dataHeader;
    export let hideClose = false;
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function handleClick() {
        dispatch('close');
    }

    $: fullScreenMobile = data.expandFullScreenMobile;

</script>
{#if data.noHeader !== true && (data.widgetTheme !== 'dotTheme' || !agentsView)}
<div class="chatwith-header"
     class:fullScreenMobile
     style={data.hideHeaderLogo?"":"padding-bottom:5px;"}>
    {#if (data.expandFullScreenMobile && !hideClose) || data.widgetPopUp}
    <div on:click={handleClick} class="close-chatwith">
        <svg aria-hidden="true" focusable="false"  data-icon="times"  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#fff" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
    </div>
    {/if}





  {#if data.hideHeaderLogo !== true }
    <div class="okewa-avatar {data.roundedLogo === true ? 'roundedLogo' : 'squareLogo'}">
        <img
            class="profile-img"
            style="{!(data.iconWidth == 60 && data.iconHeight == 60) ? 'width:'+data.iconWidth+'px; height:'+data.iconHeight+'px':''}"
            alt="" src={data.iconUrl}
            onerror="this.src='process.env.MY_HOST'+'/bk.png'"/>
    </div>



    {/if}
    <div class="chatwith-intro">

      {#if dataHeader}<div class="chatwith-intro" style="font-size: 30px !important;padding:10px 0 20px; line-height:1em">{data.name}</div>  {/if}


      {data.widgetMessage}</div>
</div>
{#if showRounded}
<div class={showRounded ==="banner" ? "chatwith-header-round-banner" : "chatwith-header-round"}></div>
{/if}
{/if}
