<script>
  export let data
</script>

<svelte:head>
  <!-- normalTheme -->
  <style lang="scss">
    @import '../scss/main.scss';
  </style>
  <!-- bubbleTheme -->
  {#if data.widgetTheme === 'bubbleTheme'}
    <style lang="scss">
      @import '../scss/theme/bubble.scss';
    </style>
    <!-- roundedTheme -->
  {:else if data.widgetTheme === 'roundedTheme'}
    <style lang="scss">
      @import '../scss/theme/rounded.scss';
    </style>
    <!-- buttonTheme -->
  {:else if data.widgetTheme === 'buttonTheme'}
    <style lang="scss">
      @import '../scss/theme/button.scss';
    </style>
    <!-- whatsappTheme -->
  {:else if data.widgetTheme === 'whatsappTheme'}
    <style lang="scss">
      @import '../scss/theme/whatsapp.scss';
    </style>
    <!-- whatsappTheme -->
  {:else if data.widgetTheme === 'dotTheme'}
    <style lang="scss">
      @import '../scss/theme/dot.scss';
    </style>
  {/if}
</svelte:head>
