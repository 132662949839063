<script>
  export let text
  export let className = ''
  export let success = false
  export let fail = false
  export let error = false
</script>

<div class="chatwith-typography {className}" class:success class:fail class:error>
  {text}
</div>
