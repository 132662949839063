<script>
  import { dayjs } from "svelte-time";
  export let bookingTime
  export let dateHeading
  export let person
  export let data
  export let failed

  let startTime = dayjs(bookingTime?.time?.substr(0, 25))?.format('HH:mm');
  let endTime = dayjs(bookingTime?.time?.substr(26))?.format('HH:mm');
</script>

<div class="chatwith-booking-form">
  {#if failed}
  <div class="chatwith-booking-form-title">{data.translateFailedSchedule ?? 'Failed Schedule'}</div>
  {:else}
  <div class="chatwith-booking-form-title">{data.translateConfirmedSchedule ?? 'Confirmed Schedule'}</div>
  <div class="chatwith-booking-form-desc">
    {data.translateYouHaveScheduledanEventWith ?? 'You have scheduled in event with'}{' '}{person.name}
  </div>
  {/if}
  <div class="chatwith-booking-event-desc">
    <i class="mi mi-calendar" />
    <div>
      {startTime + ' - ' + endTime + ', ' + dateHeading}
    </div>
  </div>
</div>
