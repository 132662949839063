<script>
  import { onDestroy } from 'svelte'
  import { getHue, padValue } from '../utils/utilities'
  import { currentLanguage } from '../store'
  import translations from '../translations'

  export let data
  export let hasBanner

  let currentLang = 'en'; // Default language

  // Subscribe to changes in currentLanguage
  currentLanguage.subscribe(value => {
      currentLang = value;
  });

  let now = Date.now()
  let end = data.countDownEnds ? new Date(data.countDownEnds) : Date.now()

  $: count = Math.round((end - now) / 1000)

  $: if (count <= 0) {
    count = 0
    clearInterval(interval)
  }

  $: d = count ? Math.floor(count / 3600 / 24) : 0
  $: h = count ? Math.floor((count - d * 3600 * 24) / 3600) : 0
  $: m = count ? Math.floor((count - d * 3600 * 24 - h * 3600) / 60) : 0
  $: s = count ? count - d * 3600 * 24 - h * 3600 - m * 60 : 0

  $: hue = getHue(data.color)

  function updateTimer() {
    now = Date.now()
  }

  let interval = setInterval(updateTimer, 1000)

  onDestroy(() => {
    clearInterval(interval)
  })
</script>

{#if data.countDownEnabled === true}
  <div class="chatwith-countdown" style="--hue:{hue}">
    <div class="chatwith-countdown-box">
      <span>{padValue(d)}</span>
      <p>{translations.days[currentLang]}</p>
    </div>
    <div class="chatwith-countdown-box">
      <span>{padValue(h)}</span>
      <p>{translations.hours[currentLang]}</p>
    </div>
    <div class="chatwith-countdown-box">
      <span>{padValue(m)}</span>
      <p>{translations.minutes[currentLang]}</p>
    </div>
    <div class="chatwith-countdown-box">
      <span>{padValue(s)}</span>
      <p>{translations.seconds[currentLang]}</p>
    </div>
  </div>
  {#if data.widgetTheme === 'bubbleTheme' && !hasBanner}
    <div class="chatwith-header-round"/>
  {/if}
{/if}
