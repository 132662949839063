<script>
  import Form from "../components/Form.svelte";
  import { fade } from "svelte/transition";

  import WidgetHeader from "../components/WidgetHeader.svelte";
  import CountDown from "../components/CountDown.svelte";
  import Mainbutton from "../components/Mainbutton.svelte";
  import Person from "../components/Person.svelte";
  import People from "../components/People.svelte";
  import { personHasFormData, send } from "../utils/sendwhatsapp_rewrite_chabi";
  import { sendform } from "../utils/sendwhatsapp_rewrite_chabi";
  import Faqs from "../components/Faqs.svelte";
  import NewFaqs from "../components/NewFaqs.svelte";
  import FaqsChat from "../components/FaqsChat.svelte";
  import { getLocalStorage } from "../utils/localStorage";
  import LeaveMessage from "../components/LeaveMessage.svelte";
  import Closer from "../components/base/Closer.svelte";
  import Chat from "../components/Chat.svelte";
  import Welcomeback from "../components/Welcomeback.svelte";
  import Banner from "../components/Banner.svelte";
  import VideoBubble from "../components/VideoBubble.svelte";
  import VideoSplash from "./VideoSplash.svelte";
  import { isReturnUser } from "../utils/localStorage";
  import QuestionForm from "../components/QuestionForm.svelte";
  import Payment from "../components/Payment.svelte";
  import Notification from "../components/Notification.svelte";
  import Booking from "../components/booking/Booking.svelte";
  import { sendBooking } from "../utils/sendwhatsapp_rewrite_chabi";
  import FaqsChatAi from "../components/FaqsChatAi.svelte";

  export let data;
  export let openWidget;
  export let embed;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  let up = data.isopen;

  let once = false;

  let videoSplashShowed = false,
    videoSplashOpen = false;
  let hideWidget = false;
  let chatwithStyles;
  let height;
  let widgetHeight;

  $: if (embed) {
    data.isopen = true;
    openWidget = true;
    data.outCloseButton = false;
  }

  $: rightpos = data.rightpos;

  $: videoSplashOpen;

  $: if (data.openFirstAvailable === true) {
    const person = data.whatsapps.find((obj) => obj.online === true && obj.form !== null);
    if (person) {
      if (once === false) {
        callme(person);
      }
    }
  }

  $: if (openWidget !== false) {
    up = openWidget;
    if (data.videoSplashUrlActivated) {
      videoSplashOpen = true;
      hideWidget = true;
    } else {
      hideWidget = false;
    }
    videoSplashShowed = true;
    openWidget = false;
  }

  $: {
    if (embed) {
      data.xAxis = 0;
      data.yAxis = 0;
    }
    chatwithStyles = "";
    if (data.expandFullScreenMobile) {
      chatwithStyles = "height: " + height + "px;";
    } else if (data.widgetTheme !== "dotTheme" || leaveamessage || showfaqs || showform) {
      chatwithStyles += "min-width: 240px;width: 260px;";
    }
    if (data.widgetPopUp) {
      if (isMobile) {
        chatwithStyles += "width: 260px;position:fixed;left:calc(50vw - 130px);bottom:calc(50vh - " + widgetHeight / 2 + "px);";
      } else {
        chatwithStyles += "width: 560px;position:fixed;left:calc(50vw - 280px);bottom:calc(50vh - " + widgetHeight / 2 + "px);";
      }
    }
  }

  $: dotTheme = data.widgetTheme === "dotTheme" && !leaveamessage && !showfaqs && !showform && !showpayment && !videoSplashOpen;

  $: fullScreenMobile = data.expandFullScreenMobile;

  $: agentsView = !(leaveamessage || showfaqs || showform || showpayment || showbooking);

  $: hasBanner =
    ((isReturnUser() && data.returningBannerUrl !== null && data.activateReturningBanner === true) ||
      (!isReturnUser() && data.bannerUrl !== null && data.ActivateBanner === true)) &&
    agentsView;

  $: showHeaderRounded = data.widgetTheme === "bubbleTheme" && !data.countDownEnabled && (!hasBanner || !agentsView);

  $: {
    if (up && fullScreenMobile) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "relative";
      document.body.style.height = "100%";
      document.body.style.background = "gray";
      document.body.parentElement.style.overflow = "hidden";
      document.body.parentElement.style.position = "relative";
      document.body.parentElement.style.height = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.height = "";
      document.body.parentElement.style.overflow = "";
      document.body.parentElement.style.position = "";
      document.body.parentElement.style.height = "";
    }
  }

  let showform = false;
  let showfaqs = false;
  let showpayment = false;
  let showbooking = false;
  let bookingConfig = {};
  let formitems = { items: [] };
  let activePerson;
  let showall = false;
  let showWelcomeMessage = false;
  let leaveamessage = false;
  let faqlevel;
  let bookingEvent = null;
  let agentAnswer = "";

  function close() {
    showform = false;
  }

  function sf(event) {
    sendform(activePerson, event, data.redirectUrl);
    if (activePerson.formBeforeFaqs) {
      showform = false;
      showfaqs = true;
    }
    if (data?.closeOnSend) {
      setTimeout(() => {
        up = false;
        showbooking = false;
        showbooking = false;
      }, 1000);
    }
  }

  function showFaqs() {
    if (activePerson.formBeforeFaqs) {
      showform = true;
      formitems = activePerson.form;
      showfaqs = false;
      return false;
    }
    if (activePerson.faqGrps.length > 0) {
      data.activeFaqs = activePerson.faqGrps[0];
      showfaqs = true;
      faqlevel = "whatsapp";
      return true;
    } else if (data.faqGrps.length > 0) {
      data.activeFaqs = data.faqGrps[0];
      showfaqs = true;
      faqlevel = "business";
      return true;
    }
    showfaqs = false;
    return false;
  }

  function problemSolved() {
    activePerson = null;
    showform = false;
    showfaqs = false;
  }

  function problemNotSolved() {
    const hasForm = personHasFormData(activePerson);
    setShowWelcomeMessage("form");

    //if there is no form and not online - nothing
    if (hasForm === false && activePerson.online === false) {
      return;
    }
    //send the whatsapp
    else if (hasForm === false || activePerson.formBeforeFaqs) {
      // if there is no form or form is before faqs, no need to show faqs
      send(activePerson, data.redirectUrl, {});
    } else {
      showfaqs = false;
      formitems = activePerson.form;
      if (activePerson?.bookingConfigs?.length > 0) {
        showbooking = true;
        bookingConfig = activePerson?.bookingConfigs[0];
      } else {
        showform = true;
      }
    }
  }

  function callme(person, inputdata = {}) {
    if (person.isExtraLink) {
      if (person.type === "payment") {
        activePerson = person;
        showpayment = true;
      }
      once = true;
      return;
    }

    activePerson = person;

    once = true;

    if (showFaqs()) {
      return;
    }

    if (person?.bookingConfigs?.length > 0) {
      showbooking = true;
      bookingConfig = person?.bookingConfigs[0];
      return;
    }

    const hasForm = personHasFormData(person);

    //if there is no form and not online - nothing
    leaveamessage = false;
    if (hasForm === false && ((person.online === false && data.offlineLeads === true) || (data.desktopLeads === true && window.innerWidth > 1000))) {
      leaveamessage = true;
      return;
    }
    //send the whatsapp
    else if (hasForm === false) {
      if (activePerson.online === true) {
        send(person, data.redirectUrl, inputdata);
      }
    } else {
      setShowWelcomeMessage("form");
      showform = true;
      formitems = person.form;
      formitems.items.forEach((a) => (a.val = ""));
    }
  }

  function handleClick() {
    agentAnswer = "";
    const person = data.whatsapps.find((obj) => obj.activateDirectlyChat === true);
    if (person && !up) {
      send(person, data.redirectUrl);
      return;
    }
    // if (showform && activePerson?.form.items[activePerson?.form.items.length - 1].val) {
    if (showform && activePerson?.form.items.length > 0 && activePerson?.form.items[0].val) {
      showform = false;
    }
    up = !up;
    if (videoSplashOpen) {
      up = false;
    }
    if (up && !videoSplashShowed && data.videoSplashUrlActivated === true && data.videoSplashUrl !== "") {
      videoSplashShowed = true;
      videoSplashOpen = true;
      hideWidget = true;
    } else {
      videoSplashOpen = false;
      hideWidget = false;
    }
  }

  function bubbleClick() {
    if (data.videoSplashUrlActivated && !videoSplashShowed) {
      videoSplashOpen = !videoSplashOpen;
      videoSplashShowed = true;
    } else {
      hideWidget = false;
      up = true;
    }
  }

  function videoClose(toChat) {
    videoSplashOpen = false;
    videoSplashShowed = true;
    hideWidget = false;
    up = true;
    if (toChat.detail) {
      const person = data.whatsapps.find((obj) => obj.id === data.videoAgent);
      if (person) {
        callme(person);
      }
    }
  }

  function leaveMessage() {
    const hasForm = personHasFormData(activePerson);
    setShowWelcomeMessage("leaveMessage");
    //if there is no form and not online - nothing
    if (
      hasForm === false &&
      ((activePerson.online === false && data.offlineLeads === true) || (data.desktopLeads === true && window.innerWidth > 1000))
    ) {
      leaveamessage = true;
      return;
    }
    if (hasForm) {
      showfaqs = false;
      formitems = activePerson.form;
      if (activePerson?.bookingConfigs?.length > 0) {
        showbooking = true;
        bookingConfig = activePerson?.bookingConfigs[0];
      } else {
        showform = true;
      }
    }
  }

  function setShowWelcomeMessage(id) {
    const key = `${activePerson.id}${id}`;
    if (getLocalStorage(key)) {
      setTimeout(() => {
        showWelcomeMessage = true;
      }, 500);
      setTimeout(() => {
        showWelcomeMessage = false;
      }, 3500);
    }
  }

  const handleBooking = (event) => {
    if (activePerson?.onboardPaymentLinks?.length > 0) {
      activePerson.onboardPaymentLinks.forEach((value) => {
        if (value.showOnWidget && value.useStripe) {
          activePerson = {
            name: value.name,
            iconUrl: value.image,
            online: true,
            isExtraLink: true,
            type: "payment",
            id: value.link,
            bookingConfigs: activePerson?.bookingConfigs,
          };
          if (!data.onboardPaymentLinks) {
            data.onboardPaymentLinks = [];
          }
          data.onboardPaymentLinks.push(value);
          showpayment = true;
          bookingEvent = event;
        } else {
          sendBooking(activePerson, event);
        }
      });
    } else {
      sendBooking(activePerson, event);
    }
    if (data?.closeOnSend) {
      setTimeout(() => {
        up = false;
        showbooking = false;
        showbooking = false;
      }, 1000);
    }
  };
</script>

<div
  id={"chatwith-widget-" + data.id}
  style="--zIndex:{data.zIndex};--xAxis:{data.xAxis}px;--yAxis:{data.yAxis}px;"
  bind:clientHeight={height}
  hidden={data.active != 1}
  class="chatwith device-{data.device}"
  class:dotTheme
  class:fullScreenMobile
  class:embed
  class:rightpos
  class:up
  class:noHeader={data.noHeader === true}
  class:popupMode={data.widgetPopUp && !isMobile}
>
  <Notification popupInfo={data.businessPopUpNotification} />
  {#if up === true}
    <div class="chatwith-widget" hidden={hideWidget}>
      {#if data.widgetTheme !== "dotTheme"}
        <Welcomeback on:close={handleClick} {data} />
      {/if}
      <div
        bind:clientHeight={widgetHeight}
        style={chatwithStyles}
        class="chatwith-content-container {embed ? '' : data.rightpos == 0 ? 'slide-in-left' : 'slide-in-right'}"
      >
        <WidgetHeader on:close={handleClick} {data} {agentsView} showRounded={showHeaderRounded} />

        {#if leaveamessage === true}
          <div class="chatwith-content" in:fade>
            <CountDown {data} {hasBanner} />
            <div class="chatwith-person-wrapper">
              <Person person={activePerson} {data} />
            </div>
            <div class="chatwith-content-wrapper">
              <Closer on:close={() => (leaveamessage = false)} />
              <div style="padding:10px">
                <LeaveMessage {data} person={activePerson} />
              </div>
            </div>
          </div>
        {:else if showfaqs}
          <div class="chatwith-content" in:fade>
            <CountDown {data} {hasBanner} />
            <div class="chatwith-person-wrapper">
              <Person person={activePerson} {data} />
            </div>
            <div class="chatwith-content-wrapper">
              {#if activePerson.aiChatFaq === true}
                <FaqsChatAi on:problem-solved={problemSolved} on:problem-not-solved={problemNotSolved} {activePerson} {data} />
              {:else if data.faqsChat === true}
                <FaqsChat
                  on:leave-message={leaveMessage}
                  on:problem-solved={problemSolved}
                  on:problem-not-solved={problemNotSolved}
                  {activePerson}
                  {faqlevel}
                  {data}
                />
              {:else if data.faqDisplay === true}
                <NewFaqs
                  on:leave-message={leaveMessage}
                  on:problem-solved={problemSolved}
                  on:problem-not-solved={problemNotSolved}
                  faqs={data.activeFaqs}
                  {activePerson}
                  {data}
                />
              {:else}
                <Faqs
                  on:leave-message={leaveMessage}
                  on:problem-solved={problemSolved}
                  on:problem-not-solved={problemNotSolved}
                  faqs={data.activeFaqs}
                  {activePerson}
                  {data}
                />
              {/if}
            </div>
          </div>
        {:else if showform && activePerson.chatform === true}
          <div class="chatwith-content" in:fade>
            <CountDown {data} {hasBanner} />
            <div class="chatwith-person-wrapper">
              <Person person={activePerson} {data} />
              <div class="chatwith-chatform-progress">
                <div class="chatwith-chatform-progress-bar" id="chatwith-chatform-progress-bar" />
              </div>
            </div>
            <div class="chatwith-content-wrapper">
              <Chat
                {data}
                {activePerson}
                on:close={() => {
                  showform = false;
                }}
                on:callmex={sf}
              />
            </div>
          </div>
        {:else if showform}
          <div class="chatwith-content" in:fade>
            <CountDown {data} {hasBanner} />
            <div class="chatwith-person-wrapper">
              <Person person={activePerson} {data} />
            </div>
            <div class="chatwith-content-wrapper">
              <Form on:callmex={sf} on:close={close} {data} person={activePerson} {formitems} />
            </div>
          </div>
        {:else if showpayment}
          <Payment
            {data}
            {bookingEvent}
            person={activePerson}
            on:close={() => {
              showpayment = false;
              showbooking = false;
            }}
          />
        {:else if showbooking}
          <div class="chatwith-content" in:fade>
            <CountDown {data} {hasBanner} />
            <div class="chatwith-person-wrapper">
              <Person person={activePerson} {data} />
            </div>
            <div class="chatwith-content-wrapper">
              <Booking on:close={() => (showbooking = false)} on:submit={handleBooking} person={activePerson} {bookingConfig} {data} />
            </div>
          </div>
        {:else}
          <div class="chatwith-content space-evenly" in:fade>
            {#if !dotTheme}
              <CountDown {data} {hasBanner} />
              <Banner {data} isReturnUser={isReturnUser()} />
            {/if}

            <div class="chatwith-people-wrapper">
              {#if data.activateQuestionForm && !agentAnswer && data.question}
                <QuestionForm
                  on:message={(v) => {
                    callme(v.detail);
                  }}
                  on:agentView={(v) => {
                    agentAnswer = v.detail;
                  }}
                  {data}
                />
              {:else}
                <People
                  on:message={(v) => {
                    callme(v.detail);
                  }}
                  {data}
                  {showall}
                  {agentAnswer}
                />
                {#if data.whatsapps.length > 9 && !agentAnswer && !dotTheme && !fullScreenMobile}
                  <button on:click={() => (showall = !showall)} class="showmore">
                    {#if showall === false}
                      {#if data.showAllAgents}
                        {data.showAllAgents}
                      {:else}
                        Show all agents
                      {/if}
                      ({data.whatsapps.length})
                    {:else if data.showLessAgents}
                      {data.showLessAgents}
                    {:else}
                      Show less agents
                    {/if}
                  </button>
                {/if}
              {/if}
            </div>
          </div>
        {/if}

        {#if !dotTheme}
          {#if data.premium !== true}
            <div class="powered-by-tochatbe">
              <a href="https://tochat.be/" title="Free WhatsApp Widget">Free Widget by ToChat.be</a>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  {/if}
  {#if videoSplashOpen === true}
    <div class="chatwith-videosplash-wrapper">
      <VideoSplash on:close={videoClose} {data} />
    </div>
  {/if}
  {#if embed != true && (data.expandFullScreenMobile != true || up != true)}
    <div class="chatwith-mainbutton-wrapper  {data.rightpos == 0 ? data.animation + '-in-left' : data.animation + '-in-right'}  ">
      {#if data.videoBubble === true && data.videoBubbleUrl && !up && !videoSplashOpen}
        <VideoBubble on:click={bubbleClick} {data} />
      {:else if !(fullScreenMobile && showbooking)}
        <Mainbutton on:close={handleClick} {data} {height} {up} {videoSplashOpen} />
      {/if}
    </div>
  {/if}
</div>
