import { Base64 } from 'js-base64';
import { tagging } from './tagging';
import { dataAttributes } from './getdata';
import { usedToChat } from './localStorage';
import axios, { post } from 'axios';
import { isClosedConversationStore } from '../store';
import { checkIfSomeItemCloseConversation } from './utilities';

let host;

/**
 * Sets the host URL for API requests.
 *
 * @param {string} h - The host URL to set.
 */
const setHost = (h) => {
    host = h;
};

/**
 * Gets the currently set host URL.
 *
 * @returns {string} The current host URL.
 */
const getHost = () => host;

/**
 * Encodes event data into a Base64 string.
 *
 * @param {Object} data - The event data to encode.
 * @returns {string} The Base64 encoded data.
 */
const encodeEventData = (data) => {
    return Base64.encodeURI(JSON.stringify(data));
};

/**
 * Constructs a parameter object and query string based on provided details.
 *
 * @param {string} inputData - The encoded data to include.
 * @param {string} [buyUrl=null] - The optional buy URL.
 * @param {obj} [booking=null] - The optional buy URL.
 * @returns {{paramObj: Object, extra: string}} The constructed parameter object and query string.
 */
const constructParams = (inputData, buyUrl = null, booking = null) => {
    const paramObj = {
        data: inputData,
        screenwidth: window.screen.width,
        url: window.location.href,
    };

    if (booking) {
        paramObj['booking'] = booking
    }

    let extra = `&url=${encodeURIComponent(window.location.href)}`;

    ['product', 'price', 'fullMessage'].forEach(attr => {
        if (dataAttributes[attr]) {
            extra += `&${attr}=${encodeURIComponent(dataAttributes[attr])}`;
            paramObj[attr] = encodeURIComponent(dataAttributes[attr]);
        }
    });

    if (buyUrl) {
        extra += `&buyUrl=${encodeURIComponent(buyUrl)}`;
        paramObj.buyUrl = encodeURIComponent(buyUrl);
    }

    return { paramObj, extra };
};

/**
 * Sends data via API or redirects the user based on specific conditions.
 *
 * @param {Object} person - The person object containing the recipient's details.
 * @param {string} [redirectUrl] - The optional URL to redirect after sending the form.
 * @param {string} [inputData=''] - The encoded data to send.
 * @param {string} [buyUrl=null] - The URL to be included as a buy link.
 * @param {boolean} [notQualified=false] - Flag to indicate if the person is not qualified for redirection.
 */
const send = async (person, redirectUrl, inputData = '', buyUrl = null, notQualified = false, isClosedConversation = false) => {
    tagging(person);
    usedToChat();

    const { paramObj, extra } = constructParams(inputData, buyUrl);
    const apiUrl = `${host}/api/business/send/${person.id}`;
    const screenWidthParam = `&screenwidth=${window.screen.width}`;


    try {
        if (!notQualified && !person.noWhatsappRedirect && !isClosedConversation) {
            window.location.href = `${apiUrl}?data=${inputData}${extra}${screenWidthParam}`;
        } else if (redirectUrl && !isClosedConversation) {
            window.open(redirectUrl);
        } else {
            await axios.post(apiUrl, paramObj);
        }
    } catch (error) {
        console.error('Error sending data:', error);
    }
};

/**
 * Sends form data to a specified person.
 *
 * @param {Object} person - The person object containing the recipient's details.
 * @param {Object} event - The event object containing the form data and other event details.
 * @param {string} [redirectUrl] - The optional URL to redirect after sending the form.
 */
const sendform = (person, event, redirectUrl) => {
    let closeConversation = checkIfSomeItemCloseConversation(event.detail.data);
    const encodedData = encodeEventData(event.detail.data);
    send(person, redirectUrl, encodedData, event.detail?.url, event.detail.notQualified, closeConversation);
};

/**
 * Uploads a file to the server for the specified WhatsApp ID.
 *
 * @param {File} file - The file to upload.
 * @param {string} whatsappId - The WhatsApp ID associated with the file.
 * @returns {Promise} A promise resolving the server response.
 */
const fileUpload = async (file, whatsappId) => {
    const url = `${host}/api/user-file?whatsappId=${whatsappId}`;
    const formData = new FormData();
    formData.append('file', file);

    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        return await post(url, formData, config);
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

/**
 * Retrieves the booking status for a specific booking configuration and time range.
 *
 * @param {string} bookingConfigId - The ID of the booking configuration.
 * @param {string} startTime - The start time for the booking.
 * @param {string} endTime - The end time for the booking.
 * @returns {Promise} A promise resolving the server response.
 */
const getBookingStatus = async (bookingConfigId, startTime, endTime) => {
    const url = `${host}/api/booking/${bookingConfigId}/status`;

    try {
        return await post(url, { startTime, endTime });
    } catch (error) {
        console.error('Error fetching booking status:', error);
        throw error;
    }
};

/**
 * Sends booking data to a specified person, handling different device and redirection logic.
 *
 * @param {Object} person - The person object containing the recipient's details.
 * @param {Object} event - The event object containing the booking data and other event details.
 */
const sendBooking = async (person, event) => {
    let isClosedConversation = false;

    isClosedConversationStore.subscribe(value => {
        isClosedConversation = value;
    });

    tagging(person);


    const encodedData = encodeEventData(event.detail.data);
    const { paramObj, extra } = constructParams(encodedData, event.detail?.url, event.detail?.booking);
    const apiUrl = `${host}/api/business/send/${person.id}`;
    const screenWidthParam = `&screenwidth=${window.screen.width}`;
    const bookingParam = `&booking=${Base64.encodeURI(JSON.stringify({ data: encodedData, booking: event.detail.booking, url: encodeURIComponent(window.location.href) }))}`;

    try {
        if (person.noWhatsappRedirect || isClosedConversation) {
            await axios.post(apiUrl, paramObj);
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            // Handle iOS-specific logic
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            a.href = `${apiUrl}?data=${encodedData}${bookingParam}${extra}${screenWidthParam}`;
            a.click();
            document.body.removeChild(a);
        } else {
            // Handle non-iOS logic
            window.open(`${apiUrl}?data=${encodedData}${bookingParam}${extra}${screenWidthParam}`);
        }
    } catch (error) {
        console.error('Error sending booking data:', error);
    }
};

/**
 * Checks if a person has form data available.
 *
 * @param {Object} person - The person object containing the form data.
 * @returns {boolean} True if the person has form data, false otherwise.
 */
const personHasFormData = (person) => {
    return person.form != null && typeof person.form === 'object' && Object.keys(person.form).length > 0 && person.form.constructor === Object;
};

export {
    personHasFormData,
    sendform,
    send,
    setHost,
    getHost,
    sendBooking,
    fileUpload,
    getBookingStatus,
};
