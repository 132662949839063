{#if activeFaq === true }
<div  class="chatwith-singlefaq" class:fullScreenMobile aria-expanded={hidden}>
    <div class="chatwith-question" on:click={() => toggle()}>
        <svg width="12" height="12" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 511.98 511.98" style="enable-background:new 0 0 511.98 511.98;" xml:space="preserve">
            <polygon points="18.468,467.029 255.99,55.638 493.497,467.029 "/>
        </svg>
        {faq.question}
    </div>
        {#if hidden === false || allowToggle===false}
            <div in:fade  class="chatwith-answer">
                {@html faq.answer}
            </div>
        {/if}
</div>
{/if}

<script>
    import { fade } from 'svelte/transition';

    export let faq;
    export let activeFaq;
    export let allowToggle;
    export let fullScreenMobile;

    let hidden=true

    const toggle = () => {
        if (allowToggle===true){
            hidden = !hidden
        }
    }

</script>

