/**
 * Sends booking data to Google Analytics.
 * Checks if the function `appointmentsRedirectConvertionTracking` is available in the global `window` object and calls it if it exists.
 *
 * @return {void} - Returns nothing.
 */
function sendBookingDataToGA(){


    if (typeof window.appointmentsRedirectConvertionTracking === 'function') {
        console.log("appointmentsRedirectConvertionTracking called");
        window.appointmentsRedirectConvertionTracking();
    }

}

export { sendBookingDataToGA }
