<script>
  import { fade } from 'svelte/transition'
  import { onMount } from 'svelte'
  import { isReturnUser } from '../utils/localStorage'
  import { createEventDispatcher } from 'svelte'
  import Closer from './base/Closer.svelte'

  const dispatch = createEventDispatcher()

  export let data

  let show = false

  onMount(() => {
    if (isReturnUser()) {
      if (!sessionStorage.getItem('tochatsess')) {
        sessionStorage.setItem('tochatsess', 'true')
        show = true
      }
      setTimeout(() => (show = false), 3000)
    }
  })  
</script>

<div class="chatwith-welcome-back">
  {#if show && data.WelcomeBackMessage}
    <div
      class="welcome-back-msg"
      in:fade={{ delay: 2000 }}
      out:fade={{ delay: 4000 }}>
      {data.WelcomeBackMessage}
    </div>
  {/if}
  {#if data.outCloseButton && !data.widgetPopUp}
    <div in:fade={{ delay: 100 }}>
      <Closer on:close={() => dispatch('close')} />
    </div>
  {/if}
</div>
