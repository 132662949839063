<script>
    import {createEventDispatcher} from "svelte";
  import { toArray, getWrittenName } from "../../utils/utilities";

    export let options;
    export let currency;
    $: value = ""

    const dispatch = createEventDispatcher();

    function action(){
        dispatch('message', value);
        value = '';
    }

    const reduceStr = (str) => {
        if(str.length > 20)
            return str.substring(0, 20) + "...";
        return str;
    }
   
</script>
<div class="scale-in-center">            
    <select bind:value={value} class="chatwith-chat-select">
    <option value="">--{reduceStr(options.label)}--</option>
    {#each toArray(options.data) as data}
        <option value={data}>{getWrittenName(data, options?.dataObject, options?.showPrices, currency)}</option>        
    {/each}
    </select>  
    <button on:click={()=>action()} class="chatwith-chat-checkbutton">
        <svg style="width: 18px;" aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
    </button>    
</div>

