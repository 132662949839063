<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	export let data;
	let scale = 1;

	$: {
		if(data.iconSize) {
		  scale = data.iconSize.slice(1);
		}
	}
	let video;

	const vids = {mp4:"mp4",avi:"avi",mpg:"mpg",mpeg:"mpeg",webm:"webm"}

	if(data.videoBubbleUrl.split('.').pop() in vids){
		setInterval(() => {
			video.play();
		}, 300);
	}

  const click = () => {
    dispatch("click");
  }

</script>

<div class="chatwith-videobubble"  on:click="{() => click()}"
	style="width:{scale*150}px;height:{scale*150}px;" >
	{#if data.videoBubbleUrl.split('.').pop() in vids}
	<video
		style="width:{scale*150+10}px;height:{scale*150+10}px;"
		src={data.videoBubbleUrl}
		bind:this={video}
		playsinline
		loop
		muted
	>
		<track kind="captions"/>
	</video>
	{:else}
	<img
		class="chatwith-img"
		style="width:{scale*150+10}px;height:{scale*150+10}px;"
		alt="tochat-whatsapp"
		src={data.videoBubbleUrl
		? data.videoBubbleUrl
		: "process.env.CDN_HOST" + "/icns/gallery/whatsapps/whatsapp-icon.png"}
	/>
	{/if}
</div>
