<script>
  import BackButton from './BackButton.svelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()
  export let showBookingForm
</script>

<div class="chatwith-booking-info">
  {#if showBookingForm}
    <div class="chatwith-booking-back" on:click={() => dispatch('back')}>
      <BackButton />
    </div>
  {/if}
</div>
