import axios from "axios";
import { DateTime as Dtime } from "luxon";
import { encodeURL } from "js-base64";
import { isMobile } from "./utilities";

const dataAttributes = {
    product: null,
    price: null,
    fullMessage: null,
};

let dataObj;

function changeTimezone(date, tz) {
    if (tz === null) {
        tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    // suppose the date is 12:00 UTC
    const invdate = new Date(
        date.toLocaleString("en-US", {
            timeZone: tz,
        })
    );

    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    const diff = date.getTime() - invdate.getTime();

    // so 12:00 in Toronto is 17:00 UTC
    return new Date(date.getTime() - diff); // needs to substract
}


function checkHolidays(whatsapp) {
    const curentdatetime = new Date().getTime();

    let from = new Date(whatsapp.holidayFrom);
    from = changeTimezone(from, whatsapp.holidayTimezone);

    let until = new Date(whatsapp.holidayUntil);
    until = changeTimezone(until, whatsapp.holidayTimezone);
    //May the end date inclusive
    until = until.getTime() + 86400000;

    if (curentdatetime >= from.getTime() && curentdatetime <= until) {
        return false;
    }
    return true;
}

function shuffle(array) {
    let counter = array.length;
    while (counter > 0) {
        let index = Math.floor(Math.random() * counter);
        counter--;
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }
    return array;
}

function getPersonId() {
    if (document.currentScript.hasAttribute("data-person")) {
        return document.currentScript.getAttribute("data-person");
    }

    return null;
}

function getkey() {
    let key = "";
    if (document.currentScript.hasAttribute("data-key")) {
        key = document.currentScript.getAttribute("data-key");
    } else if (document.currentScript.hasAttribute("key")) {
        key = document.currentScript.getAttribute("key");
    } else {
        const queryString = document.currentScript.getAttribute("src").split("key=");
        key = queryString[1];
    }
    return key;
}

function setAttributes() {
    if (document.currentScript.hasAttribute("data-product")) {
        dataAttributes.product = document.currentScript.getAttribute("data-product");
    }

    if (document.currentScript.hasAttribute("data-price")) {
        dataAttributes.price = document.currentScript.getAttribute("data-price");
    }

    if (document.currentScript.hasAttribute("data-fullmessage")) {
        dataAttributes.fullMessage = document.currentScript.getAttribute("data-fullmessage");
    }
}

async function gData(host, injectObject) {
    //console.log(host)
    setAttributes();

    const sericesurl = host + "/api/business/get/" + getkey();
    // const sericesurl = "http://localhost:8081/jsonData";
    let data = {};
    await axios
        .get(sericesurl)
        .then(function (response) {
            data = response.data.data;
            data.premium = response.data.premium == true;
            data.country = response.data.country;
            data.dialingCode = response.data.dialingCode;
            const country = response.data.country;
            data.whatsapps.forEach((element, index) => {
                data.whatsapps[index].online = datecompare(element.optimes);
                data.whatsapps[index].businessGaTag = data.gaTag;
            });

            data.whatsapps.forEach((element, index) => {
                if (data.whatsapps[index].active === true) {
                    data.whatsapps[index].active = filterCountry(country, data.whatsapps[index].countryActivate, data.whatsapps[index].country);
                    //console.log(data.whatsapps);
                }

                if (data.whatsapps[index].active === true && data.whatsapps[index].enableHolidays && data.whatsapps[index].online == true) {
                    data.whatsapps[index].online = checkHolidays(data.whatsapps[index]);
                }
            });

            // randomize only after filtering
            if (data.random === true) {
                data.whatsapps = shuffle(data.whatsapps);
                data.whatsapps = onlyOnline(data.whatsapps);
                data.whatsapps = data.whatsapps.filter((o) => o.active === true);
                const first = data.whatsapps.pop();
                data.whatsapps = [first];
            } else if (data.onlyactive === true) {
                data.whatsapps = onlyOnline(data.whatsapps);
            }

            if (data.hideIfNoone === true) {
                let someoneonline = false;
                data.whatsapps.forEach((i) => (i.online == true ? (someoneonline = true) : ""));

                if (someoneonline === false) {
                    data.active = false;
                }
            }

            //console.log(data);

            if (!window.location.href.startsWith("https://services.tochat.be/app/whatsapp"))
                data.whatsapps = data.whatsapps.filter((obj) => obj.active === true);

            if (data.active) {
                data.active = filterCountry(country, data.countryActivate, data.country);
            }
            if (data.whatsapps.length === 0) data.active = false;

            data.faqDisplay = data.alternativeFaq;

            if (data.whatsapps.find((obj) => obj.activateDirectlyChat === true)) data.isopen = false;

            if (data.expandFullScreenMobile) data.expandFullScreenMobile = isMobile();
            if (data.expandFullScreenMobile) {
                data.outCloseButton = false;
                if (data.widgetTheme === "dotTheme") {
                    data.widgetTheme = "normal";
                }
            }

            const iOS = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
            if (iOS) document.head.querySelector('meta[name="viewport"]').content = "width=device-width, initial-scale=1, maximum-scale=1";

            if (data.whatsappIconUrl === "https://widget.tochat.be/icon-1.png") {
                //this is temporarily action for website bugfix
                data.whatsappIconUrl = "https://cdn.tochat.be/icns/gallery/whatsapps/whatsapp-icon.png";
            }

            // data.widgetTheme = 'normal';
            // data.widgetTheme = 'bubbleTheme';
            //   data.widgetTheme = 'roundedTheme';
            // data.widgetTheme = 'buttonTheme';
            // data.widgetTheme = 'whatsappTheme';
            // data.widgetTheme = 'dotTheme';

            // data.countDownEnabled = true;
            // data.countDownEnds = "2022-02-25T18:43:00+02:00";
            // data.countDownFont = "Arial, sans-serif";

            //   data.activateQuestionForm = false;
            //   data.question = "what is your name?";
            //   data.agents = [{
            //     id: "dd512c11-52b1-429b-9238-73f1218ef584",
            //     name:"Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr",
            //   },{
            //     id: "dd512c11-52b1-429b-9238-73f1218ef5843",
            //     name:"Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr",
            //   },{
            //     id: "dd512c11-52b1-429b-9238-73f1218ef5841",
            //     name:"Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr",
            //   },{
            //     id: "dd512c11-52b1-429b-9238-73f1218ef5842",
            //     name:"Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr",
            //   },{
            //     id: "dd512c11-52b1-429b-9238-73f1218ef5845",
            //     name:"Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr",
            //   },{
            //     id: "6325c04f-943f-4886-8633-b7cb46f5d22e",
            //     name:"Jane",
            //   }]

            // data.expandFullScreenMobile = true;
            // data.faqDisplay = true;

            // data.whatsapps[2].online = false;

            // data.extraLinks =[
            //   {
            //   "url":"http://paypal.com/eqrubvlwebrvlwe",
            //   "label": "My paypal link1",
            //   "icon": "https://services.tochat.be/icon/capturadepantalla20200508alas205847-5eb5abf26d5a6.png",
            //   "type": "link"
            //   }
            //   ];

            // data.videoSplashUrl ="https://sveltejs.github.io/assets/caminandes-llamigos.mp4"
            // data.returningVideoSplashUrl ="https://sveltejs.github.io/assets/caminandes-llamigos.mp4"
            // data.videoSplashUrlActivated = true

            // data.videoBubble = true;
            // data.videoBubbleUrl ="https://sveltejs.github.io/assets/caminandes-llamigos.mp4"
            // data.videoBubbleUrl ="https://services.tochat.be//icns/gallery/Boy_blue.png";

            // data.videoAgent = "3ece1cfc-f52f-4a56-8284-bed15214987b";
            // data.videoAgentButtonText = "Hi there";

            // data.onboardPaymentLink = [{
            //   "name": "Webinar Whatsapp Business API",
            //   "price":"10",
            //   "currency":"USD",
            //   "description":"Acceso al Whatsapp Business API ",
            //   "requireName":true,
            //   "requirePhoneNumber":true,
            //   "requireAddress":false,
            //   "requireEmail":false,
            //   "useStripe":true,
            //   "image":"https://services.tochat.be//icns/gallery/Boy_blue.png",
            //   "useQrCode":null,
            //   "qrImageFile":null,
            //   "publickey":"pk_test_51H2CkAADIpOQd4Y0bDpnuZOKkBe0ylcam1Plw9gaO8ILs0VsOvg04dU0fC9NjwPLbJ80w21ZcGLeq6QyZmZTDxl900w3rzHbGy",
            //   "CONNECTED_STRIPE_ACCOUNT_ID":"acct_1J2vlQPBFSMfQZ8p",
            //   "locale":"locale",
            //   "link":"61c0a33c188cd",
            // },{
            //   "name": "Test Pay2",
            //   "price":"10",
            //   "currency":"USD",
            //   "description":"This is test.",
            //   "requireName":true,
            //   "requirePhoneNumber":true,
            //   "requireAddress":false,
            //   "requireEmail":true,
            //   "useStripe":true,
            //   "image":"https://services.tochat.be//icns/gallery/Boy_blue.png",
            //   "useQrCode":null,
            //   "qrImageFile":null,
            //   "publickey":"acct_1IbTBrPNi8TIK6hg",
            //   "CONNECTED_STRIPE_ACCOUNT_ID":"CONNECTED_STRIPE_ACCOUNT_ID",
            //   "locale":"locale",
            //   "link":"webinar-whatsapp-business-api",
            // }];

            const getLanguage = () =>
                navigator.userLanguage ||
                (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
                navigator.language ||
                navigator.browserLanguage ||
                navigator.systemLanguage ||
                "en";

            const locale = getLanguage();
            const formatter = new Intl.DateTimeFormat(locale, { weekday: "short", timeZone: "UTC" });
            const days = [1, 2, 3, 4, 5, 6, 7].map((day) => {
                const dd = day < 10 ? `0${day}` : day;
                return new Date(`2017-01-${dd}T00:00:00+00:00`);
            });
            //console.log("days", days.map(date => formatter.format(date)));

            const daysList = days.map((date) => formatter.format(date));

            const labelOfDays = {
                SU: 0,
                MO: 1,
                TU: 2,
                WE: 3,
                TH: 4,
                FR: 5,
                SA: 6,
            };

            data.whatsapps.forEach((whatsapp) => {
                if (whatsapp.optimes.length > 0) {
                    whatsapp.optimes.forEach((optime) => {
                        optime.day = daysList[labelOfDays[optime.day]];
                    });
                }
            });

            if (data.widgetTheme === "whatsappTheme") {
                data.color = "#115E52";
            }

            if (data.widgetTheme === "dotTheme" && data.activateQuestionForm) {
                data.widgetTheme = "normal";
            }

            data.whatsapps.forEach((whatsapp) => {
                if (whatsapp.onboardPaymentLinks) {
                    whatsapp.onboardPaymentLinks.forEach((value) => {
                        if (value.showOnWidget && value.useStripe && whatsapp.bookingConfigs?.length === 0) {
                            data.whatsapps.push({
                                name: value.name,
                                iconUrl: value.image,
                                online: true,
                                isExtraLink: true,
                                type: "payment",
                                id: value.link,
                            });
                            if (!data.onboardPaymentLinks) {
                                data.onboardPaymentLinks = [];
                            }
                            data.onboardPaymentLinks.push(value);
                        }
                    });
                }
            });

            if (data.extraLinks) {
                data.extraLinks.forEach((value) => {
                    data.whatsapps.push({
                        name: value.label,
                        iconUrl: value.icon,
                        online: true,
                        isExtraLink: true,
                        url: value.url,
                        type: value.type,
                    });
                });
            }

            const socialIcons = {
                Instagram:
                    "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-512.png",
                Twitter: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png",
                LinkedIn: "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Linkedin-512.png",
                TikTok: "https://cdn-icons-png.flaticon.com/512/4782/4782345.png",
                YouTube: "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_youtube-512.png",
            };
            if (data.extraLinksSocialNetwork) {
                data.extraLinksSocialNetwork.forEach((value) => {
                    data.whatsapps.push({
                        name: value.type,
                        iconUrl: socialIcons[value.type],
                        online: true,
                        isExtraLink: true,
                        url: value.url,
                        type: "link",
                    });
                });
            }
            dataObj = data;
        })
        .catch(function (error) {
            //console.log(error);
        });

    if (injectObject) {
        const obj = JSON.parse(injectObject);
        for (const whatsappId in obj) {
            const index = data.whatsapps.findIndex((obj) => obj.id === whatsappId);
            if (index < 0) {
                continue;
            }
            for (const field in obj[whatsappId]) {
                const hiddenIndex = data.whatsapps[index].form.items.findIndex((obj) => obj.label === field);
                if (hiddenIndex < 0) {
                    continue;
                }
                data.whatsapps[index].form.items[hiddenIndex]["value"] = obj[whatsappId][field];
            }
        }
    }
    return data;
}

function onlyOnline(obj) {
    return obj.filter((o) => o.online === true);
}

function filterCountry(userCountry, countryActivate, countries) {
    //console.log(userCountry, countryActivate, countries);
    if (!Array.isArray(countries)) {
        return true;
    }
    if (countryActivate == "KO" && countries.includes(userCountry)) {
        return false;
    } else if (countryActivate == "OK" && !countries.includes(userCountry)) {
        return false;
    }
    return true;
}

function datecompare(optimes) {
    if (optimes.length === 0) {
        return true;
    }

    const now = Dtime.local();
    let today = now.toISODate();

    const days = {
        1: "MO",
        2: "TU",
        3: "WE",
        4: "TH",
        5: "FR",
        6: "SA",
        7: "SU",
    };

    const day = now.weekday;

    let localday;

    let online = false;

    for (let i = 0; i < optimes.length; i++) {
        if (optimes[i].timezone) {
            localday = Dtime.local().setZone(optimes[i].timezone, {
                keepLocalTime: false,
            }).weekday;
            today = Dtime.local().setZone(optimes[i].timezone, { keepLocalTime: false }).toISODate();
        } else {
            localday = day;
        }

        if (optimes[i].day == days[localday]) {
            //console.log("SAME DAY")

            let start = Dtime.fromISO(today + "T" + optimes[i].availableFrom).diffNow("minutes").minutes;
            let fin = Dtime.fromISO(today + "T" + optimes[i].availableUntil).diffNow("minutes").minutes;

            if (optimes[i].timezone) {
                //console.log("TZ")
                let localtoday = Dtime.local().setZone(optimes[i].timezone, { keepLocalTime: false }).toISODate();
                start = Dtime.fromISO(localtoday + "T" + optimes[i].availableFrom)
                    .setZone(optimes[i].timezone, { keepLocalTime: true })
                    .diffNow("minutes").minutes;
                fin = Dtime.fromISO(localtoday + "T" + optimes[i].availableUntil)
                    .setZone(optimes[i].timezone, { keepLocalTime: true })
                    .diffNow("minutes").minutes;
            }

            if (start < 0 && fin > 0) {
                return true;
            }
        }
    }
    return online;
}

export { gData, getPersonId, dataAttributes, dataObj };
