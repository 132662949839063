<script>
  export let message = "";
  export let duration = 3000;
  export let visible = false;
  export let type = "success";

  let timeout;

  $: if (visible) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      visible = false;
    }, duration);
  }
</script>

<div class="chatwith-toast-message {visible ? 'cw-show' : ''} cw-{type}">
  {message}
</div>
