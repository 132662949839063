{#if activeFaq === true }
<div  class="chatwith-singlefaq chatwith-newsinglefaq" aria-expanded={hidden} class:bottomBorder class:fullScreenMobile>
    {#if noQuestion !== true}
    <div class="chatwith-question" on:click={() => toggle()}>
        <div class="chatwith-question-text">{faq.question}</div>        
        <svg  width="15" height="15" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M9 5l7 7-7 7"></path>
		</svg>
    </div>
    {/if}
    {#if hidden === false || allowToggle===false}
        <div in:fade class="chatwith-answer">
            {@html faq.answer}
        </div>
    {/if}
</div>
{/if}

<script>
    import { fade } from 'svelte/transition';

    export let faq;
    export let activeFaq;
    export let allowToggle;
    export let noQuestion;
    export let bottomBorder;
    export let fullScreenMobile;

    let hidden=true
    const toggle = () => {
        if (allowToggle===true){
            hidden = !hidden
        }
    }
</script>