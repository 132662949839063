<script>
  import { createEventDispatcher } from 'svelte'
  import { onMount } from 'svelte'
  import { isMobile } from '../../utils/utilities'

  export let hasBorder
  export let fullScreenMobile

  $: value = ''
  let chatFileUploadBox
  let file

  onMount(() => {
    if (!isMobile()) chatFileUploadBox.focus()
  })

  const dispatch = createEventDispatcher()

  function handleFileSelected(event) {
    file = event.target.files[0]
  }

  function action() {
    dispatch('message', file)
  }

  const handleKeyPress = () => {
    if (event.code == 'Enter') {
      event.preventDefault()
    }
  }
</script>

<div class="chatwith-chat-textbox" class:fullScreenMobile class:hasBorder>
  <div class="chatwith-chat-textbox-input chatwith-chat-file-input">
    <input
      type="file"
      bind:value
      accept="png, jpg, jpeg, docx, xlsx, zip, heic, pdf"
      on:change={handleFileSelected}
      bind:this={chatFileUploadBox}
      on:keypress={handleKeyPress} />
  </div>
  <div style="font-size:10px;color:#ccc">png, jpg, jpeg, docx, xlsx, zip, pdf</div>
  <div class="chatwith-chat-textbox-button">
    <button on:click={() => action()}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 32 32"
        id="icon"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style>
            .cls-1 {
              fill: none;
            }
          </style>
        </defs>
        <path
          fill="#fff"
          d="M27.45,15.11l-22-11a1,1,0,0,0-1.08.12,1,1,0,0,0-.33,1L7,16,4,26.74A1,1,0,0,0,5,28a1,1,0,0,0,.45-.11l22-11a1,1,0,0,0,0-1.78Zm-20.9,10L8.76,17H18V15H8.76L6.55,6.89,24.76,16Z" />
      </svg>
    </button>
  </div>
</div>
