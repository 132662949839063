<script>
  import { fade } from "svelte/transition";
  import { onMount, onDestroy } from "svelte";
  import { getHost } from "../utils/sendwhatsapp_rewrite_chabi";
  import axios from "axios";
  import { currentLanguage } from "../store";
  import translations from "../translations";

  export let person;
  export let data;
  export let isGrid = false;
  export let isInnerPeople = false;

  let container;
  let personDiv;
  let showTooltip = false;
  let availabilityMessage = "";
  let intervalId;
  let currentLang = "en";

  $: fullScreenMobile = data.expandFullScreenMobile;
  $: gridView = isGrid;
  $: innerPeople = isInnerPeople;
  $: leftpos = !data.rightpos;

  onMount(async () => {
    if (person.isExtraLink && person.type != "payment") {
      const child = document.createElement("a");
      child.target = person.type == "link" ? "_blank" : "_self";
      if (person.type == "link") {
        child.href = person.url;
      } else if (person.type == "email") {
        child.href = "mailto:" + person.url;
      } else if (person.type == "imessage") {
        child.href = "imessage://" + person.url + "&body=";
      } else {
        child.href = person.type + ":" + person.url;
      }
      child.style["text-decoration"] = "none";
      child.append(personDiv);
      container.append(child);
    }
    // Subscribe to changes in currentLanguage
    currentLanguage.subscribe((value) => {
      currentLang = value;
    });

    await getBookingDisponibility();
  });

  const handleMouseOver = () => {
    if (data.widgetTheme === "dotTheme" && isInnerPeople) {
      showTooltip = true;
    }
  };

  const handleMouseOut = () => {
    showTooltip = false;
  };

  // Function to get the booking availability
  const getBookingDisponibility = async () => {
    if (person.bookingConfigs && person.bookingConfigs.length > 0) {
      let bookingConfig = person.bookingConfigs[0];
      let apiUrl = getHost();
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth();
      let day = now.getDate();

      try {
        const response = await axios.get(`${apiUrl}/api/booking/${bookingConfig.id}/calendar?type=day&date=${year}-${month + 1}-${day}`);

        if (!response.data.status || response.data.status !== 404) {
          let firstBooking = response.data.filter((item) => item.bookable)[0];
          if (firstBooking) {
            // Use a regex to extract the start time from the first booking
            let timeMatch = firstBooking.time.match(/^(.+?)\+/);

            if (timeMatch && timeMatch[1]) {
              let startTimeStr = timeMatch[1]; // Extracted start time

              // Create Date objects for the start time and the current time
              let startTime = new Date(startTimeStr);

              // Calculate the difference between the start time and the current time
              updateAvailabilityMessage(startTime - now);

              // Set up the interval to update the availability message every minute (or second)
              intervalId = setInterval(() => {
                now = new Date();
                let timeDifference = startTime - now;

                // Update the message based on the current time
                updateAvailabilityMessage(timeDifference);

                // Stop the interval if the booking is available
                if (timeDifference <= 0) {
                  clearInterval(intervalId);
                }
              }, 60000);
            }
          } else {
            availabilityMessage = translations.noAvailabilityToday[currentLang];
          }
        } else {
          availabilityMessage = translations.noAvailabilityToday[currentLang];
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // Function to update the availability message
  const updateAvailabilityMessage = (timeDifference) => {
    if (timeDifference <= 0) {
      availabilityMessage = "Disponible";
    } else {
      let hours = Math.floor(timeDifference / (1000 * 60 * 60));
      let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      if (hours > 0) {
        availabilityMessage = `${translations.availableAt[currentLang]} ${hours} h, ${minutes} min`;
      } else {
        availabilityMessage = `${translations.availableAt[currentLang]} ${minutes} min`;
      }
    }
  };

  // Clear interval on component destroy
  onDestroy(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });
</script>

<div
  id={"chatwith-agent-" + person.id}
  bind:this={container}
  class="chatwith-person"
  class:fullScreenMobile
  class:innerPeople
  class:gridView
  class:leftpos
>
  <div bind:this={personDiv}>
    <div class="chatwith-singleperson {person.online === true ? 'chatwith-active' : 'chatwith-inactive'}">
      <div class="chatwith-avatar" on:mouseover={handleMouseOver} on:mouseout={handleMouseOut}>
        {#if data.widgetTheme !== "roundedTheme"}
          <img class="chatwith-avatar-pic" alt="" src={person.iconUrl} onerror="this.src='process.env.MY_HOST'+'/bk.png'" />
          <span class="chatwith-circle" />
        {/if}
      </div>
      {#if showTooltip}
        <div class="chatwith-person-tooltip" in:fade={{ delay: 20 }}>
          <div class="chatwith-name-box">
            {#if person.post && person.post != "null" && person.post != null}
              <div class="chatwith-post">{person.post}</div>
            {/if}
            <div class="chatwith-name">{person.name}</div>
          </div>
          {#if !person.isExtraLink}
            <div class="chatwith-person-info">
              <div class="chatwith-status chatwith-online">
                <span class="chatwith-circle" />
                {#if person.online === true} Online{:else} Offline{/if}
              </div>
              <div class="chatwith-availability">{availabilityMessage}</div>
            </div>
          {:else if person.type == "payment"}
            <div class="chatwith-status chatwith-online">
              <span class="chatwith-circle" />
              Buy Online
            </div>
          {/if}
        </div>
      {/if}
      {#if data.widgetTheme !== "dotTheme" || !isInnerPeople}
        <div class="chatwith-persondata">
          {#if person.post && person.post != "null" && person.post != null}
            <div class="chatwith-post">{person.post}</div>
          {/if}
          <div class="chatwith-name">{person.name}</div>
          {#if !person.isExtraLink}
            <div class="chatwith-person-info">
              <div class="chatwith-status chatwith-online">
                <span class="chatwith-circle" />
                {#if person.online === true} Online{:else} Offline{/if}
              </div>
              <div class="chatwith-availability">{availabilityMessage}</div>
            </div>
          {:else if person.type == "payment"}
            <div class="chatwith-status chatwith-online">
              <span class="chatwith-circle" />
              Buy Online
            </div>
          {/if}
        </div>
      {/if}
    </div>
  </div>
</div>
