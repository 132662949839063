<script>
  import { createEventDispatcher } from "svelte";
  import FormValidate from "../FormValidate.svelte";
  import TelField from "../base/TelField.svelte";
  import { toArray, getWrittenName, isIPhone } from "../../utils/utilities";
  import BackButton from "./BackButton.svelte";
  import { fileUpload } from "../../utils/sendwhatsapp_rewrite_chabi";
  import Spinner from "../base/Spinner.svelte";
  import Button from "../base/Button.svelte";
  import { isClosedConversationStore } from "../../store";
  import Toast from "../base/Toast.svelte";

  const dispatch = createEventDispatcher();

  export let data;
  export let person;
  export let bookingTime;
  export let bookingConfig;

  let errorList = [];
  let formValidateList = [];
  let isLoading = false;
  $: isSent = false;
  $: sendMessage = data.transSuccessMessage ?? "Your message has been sent";

  let formitems = {
    info: data.translateEnterTheDetails ?? "Enter the details",
    items: person.form.items,
    buyLinks: person.form.buyLinks,
    buttonType: "whatsapp",
    buttontext: data.translateScheduleEvent ?? "Schedule event",
  };

  let filtered = formitems.items.map((item) => item.fieldToFilterFrom);
  formitems.items.forEach((a) => (a.val = ""));

  const handleSubmit = async (url = null) => {
    if (isLoading) {
      return;
    }
    for (let i in formValidateList)
      try {
        formValidateList[i].validate();
      } catch (ex) {
        //console.log(ex);
      }
    if (errorList.length == 0) {
      for (let i in formitems.items) {
        if (formitems.items[i].type === "file" && formitems.items[i].val && !filtered[i]) {
          isLoading = true;
          const res = await fileUpload(formitems.items[i].val, person.id);
          formitems.items[i].val = res?.data?.id;
        }
        if (formitems.items[i].type === "hidden") {
          formitems.items[i].val = formitems.items[i].value ?? formitems.items[i].hval;
        }
      }
      isLoading = false;

      dispatch("submit", {
        data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
        url: url,
        booking: {
          confId: bookingConfig?.id,
          startTime: bookingTime?.time?.substr(0, 25),
          endTime: bookingTime?.time?.substr(26),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    }
  };

  const handleKeyPress = () => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
  };

  const handleBlur = (event, index) => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
    formitems.items.forEach((item, i) => {
      checkFilter(i);
    });
    if (formitems.items[index].type === "select") {
      const option = formitems.items[index].dataObject.find((obj) => obj.value === formitems.items[index].val);
      if (option.closeConversation) {
        isClosedConversationStore.set(true);
        isSent = true;
        sendMessage = option.closeConversationMessage;
        dispatch("submit", {
          data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
          booking: {
            confId: bookingConfig?.id,
            startTime: bookingTime?.time?.substr(0, 25),
            endTime: bookingTime?.time?.substr(26),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          isClosedConversation: true,
        });
      }
    }
  };

  const handleFileSelected = (e, i) => {
    formitems.items[i].val = e.target.files[0];
  };

  let isDateInput = Array(person.form.items.length).fill(false);
  let isTimeInput = Array(person.form.items.length).fill(false);

  function onDateFocus(index) {
    isDateInput[index] = true;
  }

  function onDateBlur(index) {
    isDateInput[index] = false;
  }

  function onTimeFocus(index) {
    isTimeInput[index] = true;
  }

  function onTimeBlur(index) {
    isTimeInput[index] = false;
  }

  const checkFilter = (index) => {
    if (index < 0) {
      return false;
    }
    const item = formitems.items[index];
    if (!item.fieldToFilterFrom) {
      return false;
    }
    const values = item.valueToFilterFrom.split("|");

    const checkItem = formitems.items[item.fieldToFilterFrom - 1];
    let isMatch = true;
    if (filtered[item.fieldToFilterFrom - 1] !== true) {
      values.forEach((value) => {
        if (checkItem.type === "checkbox") {
          if (checkItem.val === (value === 1 || value === "1")) {
            isMatch = false;
          }
        } else if (value.trim() === checkItem.val.trim()) {
          isMatch = false;
        }
      });
    }

    filtered[index] = isMatch;
    return isMatch;
  };
</script>

<div class="chatwith-booking-form">
  <div class="chatwith-booking-back" on:click={() => dispatch("back")}>
    <BackButton />
  </div>
  {#if formitems.info != ""}
    <div class="chatwith-booking-form-title">{formitems.info}</div>
  {/if}
  {#each formitems.items as item, i}
    <div class:hidden={filtered[i]}>
      {#if item.type == "text"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="text"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "file"}
        <div class="chatwith-booking-form-label">Please upload {item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="file"
            class={item.isError ? "error-border" : ""}
            accept=".pdf, .docx, .xlsx, .jpg, .jpeg, .png, .zip"
            on:change={(e) => handleFileSelected(e, i)}
          />
        </div>
      {:else if item.type == "number"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="number"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "url"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="url"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "date"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          {#if isIPhone()}
            {#if isDateInput[i]}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="date"
                on:blur={() => onDateBlur(i)}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {:else}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="text"
                on:focus={() => onDateFocus(i)}
                placeholder={item.label}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {/if}
          {:else}
            <input
              class="form-element-input {item.isError ? 'error-border' : ''}"
              bind:value={item.val}
              type="text"
              onfocus="(this.type='date')"
              placeholder={`${item.label}${item.required ? " *" : ""}`}
              on:keypress={handleKeyPress}
              on:change={(evt) => handleBlur(evt, i)}
            />
          {/if}
        </div>
      {:else if item.type == "time"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          {#if isIPhone()}
            {#if isTimeInput[i]}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="time"
                on:blur={() => onTimeBlur(i)}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {:else}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="text"
                on:focus={() => onTimeFocus(i)}
                placeholder={item.label}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {/if}
          {:else}
            <input
              class="form-element-input {item.isError ? 'error-border' : ''}"
              bind:value={item.val}
              type="text"
              onfocus="(this.type='time')"
              placeholder={`${item.label}${item.required ? " *" : ""}`}
              on:keypress={handleKeyPress}
              on:change={(evt) => handleBlur(evt, i)}
            />
          {/if}
        </div>
      {:else if item.type == "select"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <div class="select">
            <!-- svelte-ignore a11y-no-onchange -->
            <select bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
              <option value="">--{item.label}--</option>
              {#each toArray(item.data) as data}
                <option value={data}>{getWrittenName(data, item?.dataObject)}</option>
              {/each}
            </select>
          </div>
        </div>
      {:else if item.type == "selectAndPrice"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <div class="select">
            <!-- svelte-ignore a11y-no-onchange -->
            <select bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
              <option value="">--{item.label}--</option>
              {#each toArray(item.data) as itemData}
                <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
              {/each}
            </select>
          </div>
        </div>
      {:else if item.type == "checkbox"}
        <div class="chatwith-booking-form-label">{""}</div>
        <div class="chatwith-booking-form-input">
          <div>
            <label>
              <input value={false} bind:checked={item.val} type="checkbox" on:change={(evt) => handleBlur(evt, i)} />
              <p style="font-size: 12px">{item.label}</p>
            </label>
          </div>
        </div>
      {:else if item.type == "hidden"}
        <div class="chatwith-booking-form-label" style="display: none">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input bind:value={item.value} type="hidden" />
        </div>
      {:else if item.type == "tel"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <TelField
            {data}
            bind:value={item.val}
            bind:telNum={item.telNum}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            isError={item.isError}
            on:message={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "email"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="email"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "freetext" && item.hval}
        <div style="font-size: 13px !important;" class="chatwith-booking-form-label">
          {@html item.hval}
        </div>
      {/if}
      {#if !filtered[i]}
        <FormValidate
          bind:value={errorList}
          bind:this={formValidateList[i]}
          on:error={(val) => {
            item.isError = val.detail;
          }}
          {item}
          {data}
          index={i}
        />
      {/if}
      {#if item.type == "checkbox" && item.legalUrl}
        <div style="font-size: 12px" class="chatwith-booking-form-label">{""}</div>
        <div class="chatwith-booking-form-input">
          <a style="font-size: 12px" target="_blank" href={item.legalUrl}>{item.legalUrlText ?? "Click here to see the Privacy Policy"}</a>
        </div>
      {/if}
    </div>
  {/each}
  {#if isLoading}
    <Spinner />
  {/if}
  {#if isSent}
    <Toast message={sendMessage} visible={isSent} />
  {/if}
  {#if formitems.buyLinks && formitems.buyLinks.length > 0}
    {#each formitems.buyLinks as item, i}
      <Button
        id={"chatwith-buy-button" + i}
        on:click={() => handleSubmit(item.link)}
        buttonText={item.buttontext}
        showIcon={true}
        iconUrl={data.whatsappIconUrl}
        spanClassName={item.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
      />
    {/each}
  {:else}
    <button class="chatwith-booking-form-btn" on:click={() => handleSubmit(null)}>
      {formitems.buttontext}
    </button>
  {/if}
</div>
