<script>
  import { slide } from "svelte/transition";
  import Person from "../components/Person.svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
  export let showall;
  export let agentAnswer;

  $: buttonTheme = data.widgetTheme === "buttonTheme";
  $: dotTheme = data.widgetTheme === "dotTheme";
  $: fullScreenMobile = data.expandFullScreenMobile;
  $: scrollExtended = showall === true && data.whatsapps.length > 9;

  let agentIdList = data.agents.filter((obj) => obj.name == agentAnswer).map((obj) => obj.id);

  let agents = agentAnswer ? data.whatsapps.filter((person) => agentIdList.includes(person.id)) : data.whatsapps;
</script>

<div class="chatwith-people" class:fullScreenMobile class:scrollExtended>
  {#each agents as person, i}
    {#if i < 9 || showall || dotTheme || fullScreenMobile || agentAnswer}
      {#if agents.length <= 9 || buttonTheme || dotTheme || fullScreenMobile}
        <div
          transition:slide|local
          class="chatwith-people-person"
          style="{data.outCloseButton && !buttonTheme ? 'padding: 20px 10px 20px 10px;' : ''};{agents.length - 1 == i && 'border-bottom:0px;'};"
          on:click={() => dispatch("message", person)}
        >
          <Person {person} {data} isInnerPeople={true} />
        </div>
      {:else if agents.length <= 12}
        {#if i % 2 === 0}
          <div class="chatwith-people-list">
            <div transition:slide|local class="chatwith-people-person-vertical" on:click={() => dispatch("message", agents[i])}>
              <Person person={agents[i]} {data} isGrid={true} isInnerPeople={true} />
            </div>
            {#if agents.length > i + 1}
              <div transition:slide|local class="chatwith-people-person-vertical" on:click={() => dispatch("message", agents[i + 1])}>
                <Person person={agents[i + 1]} {data} isGrid={true} isInnerPeople={true} />
              </div>
            {/if}
          </div>
        {/if}
      {:else if i % 3 === 0}
        <div class="chatwith-people-list">
          <div transition:slide|local class="chatwith-people-person-vertical" on:click={() => dispatch("message", agents[i])}>
            <Person person={agents[i]} {data} isGrid={true} isInnerPeople={true} />
          </div>
          {#if agents.length > i + 1}
            <div transition:slide|local class="chatwith-people-person-vertical" on:click={() => dispatch("message", agents[i + 1])}>
              <Person person={agents[i + 1]} {data} isGrid={true} isInnerPeople={true} />
            </div>
          {/if}
          {#if agents.length > i + 2}
            <div transition:slide|local class="chatwith-people-person-vertical" on:click={() => dispatch("message", agents[i + 2])}>
              <Person person={agents[i + 2]} {data} isGrid={true} isInnerPeople={true} />
            </div>
          {/if}
        </div>
      {/if}
    {/if}
  {/each}
</div>
