import { writable } from 'svelte/store';

// Function to detect browser language or fallback to a default
function detectBrowserLanguage() {
    const browserLanguage = navigator.language.split('-')[0]; // Get language code (e.g., 'en' from 'en-US')
    // Add more logic as needed to validate or adjust detected language

    return browserLanguage;
}

// Default language or detected browser language
export const currentLanguage = writable(detectBrowserLanguage() || 'en');

// close conversation status
export const isClosedConversationStore = writable(false);  // Initialize with false