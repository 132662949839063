<div class="{person.online === true?'chatwith-active':'chatwith-inactive'}">
    <div class="chatwith-status chatwith-online">
        <span class="chatwith-circle"></span>
        {#if person.online === true} Online{:else} Offline{/if}
    </div>
</div>

<script>
    export let person;
</script>
